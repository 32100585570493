import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Split from 'react-split';
import clsx from 'clsx';
import {
    makeStyles, Grid, useTheme,
    Button, Tooltip,
} from '@material-ui/core';
import update from 'immutability-helper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import {
    ENTITY_SECTION,
    ENTITY_TYPE,
    ENTITY_FIELD_NAME,
} from 'utils/enum/BusinessIntelligenceEnum';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import TableUtils from 'utils/TableUtils';
import ButtonStyles from 'styles/theme/Button';
import SettingsStyle from 'styles/modules/businessIntelligence/SettingsStyle';
import UserQuery from 'services/graphQL/query/UserQuery';
import BIQuery from 'services/graphQL/query/businessIntelligence/Query';
import BIMutation from 'services/graphQL/mutate/businessIntelligence/Mutation';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import Search from 'components/widgets/Search';
import Select from 'components/widgets/Select';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import AddCategoryDialog from 'components/modules/settings/businessIntelligence/AddCategoryDialog';
import AddEntityDialog from 'components/modules/settings/businessIntelligence/AddEntityDialog';
import CloneEntityDialog from 'components/modules/settings/businessIntelligence/CloneEntityDialog';

// icons
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';
import DateUtils, { DateFormat, Period } from 'lib/DateUtils';
import moment from 'moment';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => SettingsStyle.content(theme));

const INIT_STATE = {
    categories: [],
    charts: [],
    rules: [],
    goals: [],
    users: [],
    globalCharts: [],
    isCategoryPanelOpen: false,
    isClonePanelOpen: false,
    isEntityDialogOpen: false,
    isDeletePromptVisible: false,
    selectedCategory: null,
    selectedRule: null,
    selectedChart: null,
    categoriesSearch: null,
    entitySearch: null,
    deleteDescription: null,
    deleteSection: null,
    selectedRuleLabel: null,
    selectedRuleQuery: null,
    selectedRuleCategory: null,
    selectedGoalCategory: null,
    selectedRuleInstructions: null,
    selectedRuleUsers: [],
    selectedRuleUsersBackUp: [],
    selectedChartLabel: null,
    selectedChartQuery: null,
    selectedChartCategory: null,
    selectedChartType: null,
    selectedChartOptions: null,
    selectedChartLoadedColumns: [],
    selectedChartExtraOptions: null,
    selectedChartUsers: [],
    selectedChartUsersBackUp: [],
    isCustomReport: false,
    chartKey: null,
    // Schedule Report
    frequency: null,
    timeHour: 0,
    timeMinute: 0,
    timeAmPm: Period.AM,
    startOn: null,
    assignedUsers: [],
    selectedAssignedUser: null,
    globalSearch: null,
};

const ACTION_TYPES = {
    SET_GOALS: 'setGoals',
    SET_USERS: 'setUsers',
    SET_ASSIGNED_USERS: 'setAssignedUsers',
    SET_RULES: 'setRules',
    UPDATE_FIELDS: 'updateFields',
    RESET_SCHEDULE_REPORT: 'resetScheduleReport',
    SET_SCHEDULE_REPORT: 'setScheduleReport',
    SET_GLOBAL_SEARCH: 'setGlobalSearch',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_GOALS:
        return {
            ...state,
            goals: action.value,
        };
    case ACTION_TYPES.SET_USERS:
        return {
            ...state,
            users: action.value,
        };
    case ACTION_TYPES.SET_ASSIGNED_USERS:
        return {
            ...state,
            selectedRuleUsers: action.value,
            selectedRuleUsersBackUp: action.value,
            selectedChartUsers: action.value,
            selectedChartUsersBackUp: action.value,
        };
    case ACTION_TYPES.SET_RULES:
        return {
            ...state,
            rules: action.value,
        };
    case ACTION_TYPES.UPDATE_FIELDS:
        return {
            ...state,
            ...action.value,
        };
    case ACTION_TYPES.SET_SCHEDULE_REPORT:
        return update(state, {
            [action.field]: { $set: action.value },
        });
    case ACTION_TYPES.RESET_SCHEDULE_REPORT:
        return update(state, {
            frequency: { $set: null },
            timeHour: { $set: 0 },
            timeMinute: { $set: 0 },
            timeAmPm: { $set: Period.AM },
            startOn: { $set: null },
        });
    case ACTION_TYPES.SET_GLOBAL_SEARCH:
        return update(state, {
            globalSearch: { $set: action.value },
            globalCharts: { $set: [] },
        });
    default:
        return state;
    }
};

const EntitySettings = ({ canWrite, type }) => {
    const isRuleEntity = type === ENTITY_TYPE.RULE;
    const isCustomReports = type === ENTITY_TYPE.CUSTOM_REPORT;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, dispatch] = useReducer(reducer, INIT_STATE);

    const {
        data: globalChartsData,
        loading: loadingGlobalCharts,
        error: errorLoadingGlobalCharts,
    } = useQuery(BIQuery.PULL_CHARTS_GLOBAL_REPOSITORY, {
        variables: {
            search: state.globalSearch,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: categoriesData,
        loading: loadingCategories,
        error: errorLoadingCategories,
        refetch: refetchCategories,
    } = useQuery(BIQuery.PULL_AVAILABLE_CATEGORIES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    const {
        data: rulesData,
        loading: loadingRules,
        error: errorLoadingRules,
        refetch: refetchRules,
    } = useQuery(BIQuery.PULL_AVAILABLE_RULES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
        skip: !isRuleEntity || (isRuleEntity && state.categories.length === 0),
    });

    const {
        data: chartsData,
        loading: loadingCharts,
        error: errorLoadingCharts,
        refetch: refetchCharts,
    } = useQuery(BIQuery.PULL_AVAILABLE_CHARTS, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
        skip: isRuleEntity || (!isRuleEntity && state.categories.length === 0),
    });

    const [getDataPreview, { loading: loadingColumns }] = useLazyQuery(BIQuery.PREVIEW_QUERY_RESULTS, {
        onCompleted: (response) => {
            if (response) {
                const data = response.previewQueryResults;
                if (data) {
                    const {
                        output: { records, total },
                        defaultDateValues,
                        defaultCustomFilterValues,
                    } = data;
                    dispatch({
                        type: ACTION_TYPES.UPDATE_FIELDS,
                        value: {
                            selectedChartLoadedColumns: records,
                            selectedChartExtraOptions: {
                                total,
                                defaultDateValues,
                                defaultCustomFilterValues,
                            },
                            chartKey: TableUtils.generateUUID(),
                        },
                    });
                }
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    selectedChartOptions: null,
                },
            });
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [getGoals] = useLazyQuery(BIQuery.PULL_AVAILABLE_GOALS, {
        onCompleted: (response) => {
            if (response) {
                const goals = response.pullAvailableGoals;
                dispatch({
                    type: ACTION_TYPES.SET_GOALS,
                    value: goals,
                });
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [getUsers, { loading: loadingUsers }] = useLazyQuery(UserQuery.GET_USERS_BY_COMPANY, {
        onCompleted: (response) => {
            if (response) {
                const users = response.getUsersByCompany;
                dispatch({
                    type: ACTION_TYPES.SET_USERS,
                    value: users,
                });
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [getAssignedUsers] = useLazyQuery(BIQuery.PULL_ASSIGNED_USERS, {
        onCompleted: (response) => {
            if (response) {
                const assignedUsers = [];
                const data = response.pullAssignedUsers || [];
                data.forEach((assigned) => {
                    const user = state.users.find((el) => el.userId === assigned.userId);
                    if (user) {
                        assignedUsers.push({
                            firstName: user.firstName,
                            lastName: user.lastName,
                            employeeType: user.employeeType,
                            userPicture: user.userPicture,
                            userId: user.userId,
                            assignedUserId: !state.isClonePanelOpen ? assigned.id : null,
                            scheduleReport: assigned.scheduleReport,
                        });
                    }
                });

                dispatch({
                    type: ACTION_TYPES.SET_ASSIGNED_USERS,
                    value: assignedUsers,
                });
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [saveCategory, { loading: savingCategory }] = useMutation(BIMutation.SAVE_CATEGORY, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.UPDATE_FIELDS,
                    value: {
                        categoriesSearch: null,
                        entitySearch: null,
                        isCategoryPanelOpen: false,
                        selectedCategory: response?.saveCategory,
                    },
                });

                refetchCategories();
                ModalUtils.successMessage(null, 'Category saved sucessfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [copyGlobalResource, { loading: copyingGlobalResource }] = useMutation(BIMutation.COPY_GLOBAL_RESOURCE, {
        onCompleted: (response) => {
            if (response) {
                const chartId = response.copyGlobalResourceToLocal;
                dispatch({
                    type: ACTION_TYPES.UPDATE_FIELDS,
                    value: {
                        entitySearch: null,
                        isEntityDialogOpen: false,
                        isDeletePromptVisible: false,
                        isClonePanelOpen: false,
                        deleteSection: null,
                        selectedChart: chartId,
                    },
                });

                refetchCharts();
                ModalUtils.successMessage(null, 'Resource copied sucessfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [saveAssignedUser] = useMutation(BIMutation.SAVE_ASSIGNED_USER, {
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [saveChart, { loading: savingChart }] = useMutation(BIMutation.SAVE_CHART, {
        onCompleted: (response) => {
            const chartId = response?.saveChart;
            const users = state.selectedChartUsers;
            const backUp = state.selectedChartUsersBackUp || [];

            const toSave = [];
            users.forEach((user) => {
                toSave.push({
                    variables: {
                        entityId: chartId,
                        type: ENTITY_TYPE.CHART,
                        userId: user.userId,
                        scheduleReport: user.scheduleReport,
                        ...(user.assignedUserId ? { id: user.assignedUserId } : {}),
                    },
                });
            });

            const toDelete = backUp.filter((user) => !users.some((el) => el.userId === user.userId));
            if (toDelete.length > 0) {
                toDelete.forEach((user) => {
                    toSave.push({
                        variables: {
                            active: false,
                            entityId: chartId,
                            type: ENTITY_TYPE.CHART,
                            userId: user.userId,
                            id: user.assignedUserId,
                        },
                    });
                });
            }

            if (toSave.length > 0) toSave.forEach((element) => saveAssignedUser(element));
            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    entitySearch: null,
                    isEntityDialogOpen: false,
                    isDeletePromptVisible: false,
                    isClonePanelOpen: false,
                    deleteSection: null,
                    selectedCategory: state.selectedChartCategory || state.selectedCategory,
                    selectedChart: !state.deleteSection ? chartId : null,
                },
            });

            refetchCharts();
            ModalUtils.successMessage(null, ` Chart ${state.deleteSection ? 'deleted' : 'saved'} sucessfully`);
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [saveRule, { loading: savingRule }] = useMutation(BIMutation.SAVE_RULE, {
        onCompleted: (response) => {
            if (response) {
                const ruleId = response?.saveRule;
                const users = state.selectedRuleUsers;
                const backUp = state.selectedRuleUsersBackUp || [];

                const toSave = [];
                users.forEach((user) => {
                    toSave.push({
                        variables: {
                            entityId: ruleId,
                            type: ENTITY_TYPE.RULE,
                            userId: user.userId,
                            ...(user.assignedUserId ? { id: user.assignedUserId } : {}),
                        },
                    });
                });

                const toDelete = backUp.filter((user) => !users.some((el) => el.userId === user.userId));
                if (toDelete.length > 0) {
                    toDelete.forEach((user) => {
                        toSave.push({
                            variables: {
                                active: false,
                                entityId: ruleId,
                                type: ENTITY_TYPE.RULE,
                                userId: user.userId,
                                id: user.assignedUserId,
                            },
                        });
                    });
                }

                if (toSave.length > 0) toSave.forEach((element) => saveAssignedUser(element));
                dispatch({
                    type: ACTION_TYPES.UPDATE_FIELDS,
                    value: {
                        entitySearch: null,
                        isEntityDialogOpen: false,
                        isDeletePromptVisible: false,
                        isClonePanelOpen: false,
                        deleteSection: null,
                        selectedCategory: state.selectedRuleCategory || state.selectedCategory,
                        selectedRule: !state.deleteSection ? ruleId : null,
                    },
                });

                refetchRules();
                ModalUtils.successMessage(null, ` Rule ${state.deleteSection ? 'deleted' : 'saved'} sucessfully`);
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [deleteCategory, { loading: deletingCategory }] = useMutation(BIMutation.DELETE_CATEGORY, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.UPDATE_FIELDS,
                    value: {
                        categoriesSearch: null,
                        entitySearch: null,
                        isDeletePromptVisible: false,
                        selectedCategory: null,
                        selectedRule: null,
                        selectedChart: null,
                        deleteSection: null,
                    },
                });

                refetchCategories();
                ModalUtils.successMessage(null, 'Category deleted sucessfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (errorLoadingGlobalCharts) {
            ModalUtils.errorMessage(errorLoadingGlobalCharts?.graphQLErrors);
            return;
        }

        if (!loadingGlobalCharts) {
            const data = globalChartsData?.pullChartsInGlobalRepository;
            if (data) {
                dispatch({
                    type: ACTION_TYPES.UPDATE_FIELDS,
                    value: {
                        globalCharts: data,
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingGlobalCharts, errorLoadingGlobalCharts]);

    useEffect(() => {
        if (errorLoadingCategories) {
            ModalUtils.errorMessage(errorLoadingCategories?.graphQLErrors);
            return;
        }

        if (!loadingCategories) {
            const categories = categoriesData?.pullAvailableCategories;
            if (categories) {
                getUsers();

                dispatch({
                    type: ACTION_TYPES.UPDATE_FIELDS,
                    value: {
                        categories,
                        ...(categories.length > 0 && !state.selectedCategory ? { selectedCategory: categories[0]?.id } : {}),
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCategories, errorLoadingCategories]);

    const setAssignedUsers = (data) => [...new Set(data.reduce((a, { assignedUsers }) => [...a, ...assignedUsers.map((x) => x.userId)], []))];

    useEffect(() => {
        if (errorLoadingRules) {
            ModalUtils.errorMessage(errorLoadingRules?.graphQLErrors);
            return;
        }

        if (!loadingRules) {
            const rules = rulesData?.pullAvailableRules;
            if (rules) {
                const filtered = rules.filter((rule) => rule.category.id === state.selectedCategory);
                dispatch({
                    type: ACTION_TYPES.UPDATE_FIELDS,
                    value: {
                        rules,
                        assignedUsers: setAssignedUsers(rules),
                        selectedAssignedUser: null,
                    },
                });

                // eslint-disable-next-line no-use-before-define
                if (filtered.length > 0 && !state.selectedRule) setSelectedElement(ENTITY_SECTION.RULES, filtered[0].id, rules);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingRules, errorLoadingRules]);

    useEffect(() => {
        if (errorLoadingCharts) {
            ModalUtils.errorMessage(errorLoadingCharts?.graphQLErrors);
            return;
        }

        if (!loadingCharts) {
            const charts = (chartsData?.pullAvailableCharts ?? [])
                .filter((chart) => chart.customReport === isCustomReports);
            const filteredPerCategory = charts.filter((chart) => chart.category.id === state.selectedCategory);
            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    charts,
                    assignedUsers: setAssignedUsers(charts),
                    selectedAssignedUser: null,
                },
            });

            if (filteredPerCategory.length > 0) {
                if (!state.selectedChart) {
                    // eslint-disable-next-line no-use-before-define
                    setSelectedElement(ENTITY_SECTION.CHARTS, filteredPerCategory[0].id, charts);
                    return;
                }

                if (
                    state.selectedChart
                    && charts.find((c) => c.id === state.selectedChart)
                ) {
                    // eslint-disable-next-line no-use-before-define
                    setSelectedElement(ENTITY_SECTION.CHARTS, state.selectedChart, charts);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCharts, errorLoadingCharts]);

    useEffect(() => {
        if (state.isClonePanelOpen) {
            if (isRuleEntity) getAssignedUsers({ variables: { id: state.selectedRule, type: ENTITY_TYPE.RULE } });
            if (!isRuleEntity) getAssignedUsers({ variables: { id: state.selectedChart, type: ENTITY_TYPE.CHART } });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isClonePanelOpen]);

    const actionFilter = (section, input) => {
        switch (section) {
        case ENTITY_SECTION.CATEGORIES:
            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    categoriesSearch: input,
                    selectedCategory: null,
                    selectedRule: null,
                    selectedChart: null,
                },
            });
            break;
        case ENTITY_SECTION.RULES:
        case ENTITY_SECTION.CHARTS:
            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    entitySearch: input,
                    selectedRule: null,
                    selectedChart: null,
                },
            });
            break;
        default:
            break;
        }
    };

    const getScheduleReportDateHour = (date) => {
        const startOn = new Date(date);
        let hour = startOn.getHours();
        const minute = startOn.getMinutes();
        const period = hour >= 12 ? Period.PM : Period.AM;

        // Convert to 12-hour format
        hour %= 12;
        hour = hour === 0 ? 12 : hour;

        return {
            startOn,
            timeHour: hour,
            timeMinute: minute,
            timeAmPm: period,
        };
    };

    const onChangeFrequency = (field, value) => {
        if (value == null) {
            dispatch({
                type: ACTION_TYPES.RESET_SCHEDULE_REPORT,
            });
            return;
        }

        if (value !== null && state.startOn == null) {
            const scheduleReportDateHour = getScheduleReportDateHour(moment().add(10, 'minute').format(DateFormat.DEFAULT_DATETIME));
            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    ...scheduleReportDateHour,
                    frequency: value,
                },
            });
            return;
        }

        dispatch({
            field,
            value,
            type: ACTION_TYPES.SET_SCHEDULE_REPORT,
        });
    };

    const onChangeScheduleReport = (field, value) => {
        dispatch({
            field,
            value,
            type: ACTION_TYPES.SET_SCHEDULE_REPORT,
        });
    };

    const setSelectedElement = (section, id, data) => {
        switch (section) {
        case ENTITY_SECTION.CATEGORIES:
            let entityId = null;
            const rules = state.rules.filter((rule) => rule.category.id === id);
            const charts = state.charts.filter((chart) => chart.category.id === id);

            if (isRuleEntity && rules.length > 0) {
                entityId = rules[0]?.id;
                setSelectedElement(ENTITY_SECTION.RULES, entityId);
            }

            if (!isRuleEntity && charts.length > 0) {
                entityId = charts[0]?.id;
                setSelectedElement(ENTITY_SECTION.CHARTS, entityId);
            }

            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    selectedCategory: id,
                },
            });
            break;
        case ENTITY_SECTION.RULES:
            const rule = (data || state.rules).find((el) => el.id === id);
            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    selectedRule: id,
                    selectedRuleLabel: rule.label,
                    selectedRuleQuery: rule.query,
                    selectedRuleInstructions: rule.instructions,
                },
            });
            break;
        case ENTITY_SECTION.CHARTS:
            const chart = (data || state.charts).find((el) => el.id === id);
            const scheduleReport = {
                frequency: null,
                timeHour: 0,
                timeMinute: 0,
                timeAmPm: Period.AM,
                startOn: null,
            };

            if (chart.startOn && DateUtils.isValid(chart.startOn)) {
                const currentScheduleReport = getScheduleReportDateHour(chart.startOn);

                scheduleReport.startOn = currentScheduleReport.startOn;
                scheduleReport.frequency = chart.frequency;
                scheduleReport.timeHour = currentScheduleReport.timeHour;
                scheduleReport.timeMinute = currentScheduleReport.timeMinute;
                scheduleReport.timeAmPm = currentScheduleReport.timeAmPm;
            }

            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    selectedChart: id,
                    selectedChartLabel: chart.label,
                    selectedChartQuery: chart.query,
                    selectedChartType: chart.type,
                    selectedChartOptions: JSON.parse(chart.options),
                    selectedChartLoadedColumns: [],
                    isCustomReport: chart.customReport,
                    ...scheduleReport,
                },
            });
            break;
        default:
            break;
        }
    };

    const toggleClonePrompt = () => {
        dispatch({
            type: ACTION_TYPES.UPDATE_FIELDS,
            value: {
                isClonePanelOpen: !state.isClonePanelOpen,
            },
        });
    };

    const pullQueryData = (payload, filter = {}, currentFiltersInChart = []) => {
        const input = payload;
        const { selectedChartOptions } = state;
        const { filters } = selectedChartOptions ?? {};

        input.variables.filters = BIHelper.buildFiltersData(filters, filter, currentFiltersInChart);
        getDataPreview(input);
    };

    const toggleCreationDialog = (section, editing = false) => {
        if (section === ENTITY_SECTION.CATEGORIES) {
            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    isCategoryPanelOpen: !state.isCategoryPanelOpen,
                    ...(!editing && !state.isCategoryPanelOpen ? { selectedCategory: null } : {}),
                },
            });
        }

        if (section === ENTITY_SECTION.RULES) {
            if (!state.isEntityDialogOpen) {
                getGoals();
                if (editing) getAssignedUsers({ variables: { id: state.selectedRule, type: ENTITY_TYPE.RULE } });
            }

            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    isEntityDialogOpen: !state.isEntityDialogOpen,
                    selectedRuleCategory: state.selectedCategory,
                    selectedGoalCategory: state.selectedCategory,
                    ...(!state.isEntityDialogOpen ? {
                        selectedRule: editing ? state.selectedRule : null,
                        selectedRuleLabel: editing ? state.selectedRuleLabel : null,
                        selectedRuleQuery: editing ? state.selectedRuleQuery : null,
                        selectedRuleInstructions: editing ? state.selectedRuleInstructions : null,
                        selectedRuleUsers: editing ? state.selectedRuleUsers : [],
                    } : {}),
                },
            });
        }

        if (section === ENTITY_SECTION.CHARTS) {
            if (!state.isEntityDialogOpen) {
                getGoals();
                if (editing) {
                    getAssignedUsers({ variables: { id: state.selectedChart, type: ENTITY_TYPE.CHART } });
                    pullQueryData({
                        variables: {
                            statement: state.selectedChartQuery,
                        },
                    });
                }
            }

            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    isEntityDialogOpen: !state.isEntityDialogOpen,
                    selectedChartCategory: state.selectedCategory,
                    selectedGoalCategory: state.selectedCategory,
                    ...(!state.isEntityDialogOpen ? {
                        selectedChart: editing ? state.selectedChart : null,
                        selectedChartLabel: editing ? state.selectedChartLabel : null,
                        selectedChartQuery: editing ? state.selectedChartQuery : null,
                        selectedChartType: editing ? state.selectedChartType : null,
                        selectedChartOptions: editing ? state.selectedChartOptions : null,
                        selectedChartLoadedColumns: editing ? state.selectedChartLoadedColumns : [],
                        selectedChartUsers: editing ? state.selectedChartUsers : [],
                        isCustomReport: isCustomReports,
                        ...(editing ? { chartKey: TableUtils.generateUUID() } : {}),
                    } : {}),
                    ...(state.isEntityDialogOpen ? { chartKey: null } : {}),
                },
            });
        }
    };

    const onSaveCategory = (name) => {
        if (state.selectedCategory) {
            saveCategory({
                variables: {
                    id: state.selectedCategory,
                    name,
                },
            });

            return;
        }

        saveCategory({
            variables: {
                name,
            },
        });
    };

    const toggleDeletePrompt = (section) => {
        let deleteDescription = null;
        if (section) {
            if (section === ENTITY_SECTION.CATEGORIES) deleteDescription = 'Removing this category will remove its associated rules, charts & goals, continue?';
            if (section === ENTITY_SECTION.RULES) deleteDescription = 'Do you want to remove this rule?';
            if (section === ENTITY_SECTION.CHARTS) deleteDescription = 'Do you want to remove this chart?';
            if (section === ENTITY_SECTION.USERS) deleteDescription = 'Do you want to remove the selected user from all reports?';
        }

        dispatch({
            type: ACTION_TYPES.UPDATE_FIELDS,
            value: {
                isDeletePromptVisible: !state.isDeletePromptVisible,
                deleteSection: section,
                deleteDescription,
            },
        });
    };

    const onDeleteAction = async () => {
        const isCategory = state.deleteSection === ENTITY_SECTION.CATEGORIES;
        if (isCategory) {
            deleteCategory({
                variables: {
                    id: state.selectedCategory,
                },
            });
            return;
        }

        const isUsers = state.deleteSection === ENTITY_SECTION.USERS;
        if (isUsers) {
            const { selectedAssignedUser } = state;

            await saveAssignedUser({
                variables: {
                    active: false,
                    userId: selectedAssignedUser,
                },
            });

            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    isDeletePromptVisible: false,
                    deleteSection: null,
                },
            });

            if (isRuleEntity) refetchRules();
            if (!isRuleEntity) refetchCharts();
            ModalUtils.successMessage(null, 'User has been removed from all reports');

            return;
        }

        const rule = state.rules.find((el) => el.id === state.selectedRule);
        if (rule) {
            saveRule({
                variables: {
                    id: state.selectedRule,
                    categoryId: rule.category.id,
                    label: rule.label,
                    query: rule.query,
                    instructions: rule.instructions,
                    active: false,
                },
            });
            return;
        }

        const chart = state.charts.find((el) => el.id === state.selectedChart);
        if (chart) {
            saveChart({
                variables: {
                    id: state.selectedChart,
                    categoryId: chart.category.id,
                    label: chart.label,
                    query: chart.query,
                    type: chart.type,
                    options: chart.options,
                    active: false,
                    customReport: chart.customReport,
                },
            });
        }
    };

    const onChange = ({ target: { name, value } }) => {
        let selectedUsers = [];
        if (name === ENTITY_FIELD_NAME.SELECTED_EMPLOYEE_TYPE) {
            const selected = isRuleEntity ? state.selectedRuleUsers : state.selectedChartUsers;
            const users = state.users.filter((user) => user.employeeType === value && !selected.some((u) => u.userId === user.userId));

            selectedUsers = [...selected, ...users];
        }

        dispatch({
            type: ACTION_TYPES.UPDATE_FIELDS,
            value: {
                [isRuleEntity ? name.replace('Entity', 'Rule') : name.replace('Entity', 'Chart')]: value,
                ...(name === ENTITY_FIELD_NAME.SELECTED_RULE_USER ? {
                    selectedRuleUsers: [...state.selectedRuleUsers, state.users.find((user) => user.userId === value)],
                } : {}),
                ...(name === ENTITY_FIELD_NAME.SELECTED_CHART_USER ? {
                    selectedChartUsers: [...state.selectedChartUsers, state.users.find((user) => user.userId === value)],
                } : {}),
                ...(name === ENTITY_FIELD_NAME.SELECTED_ENTITY_TYPE && !StringUtils.isEmpty(state.selectedChartType) && value !== state.selectedChartType ? {
                    selectedChartOptions: null,
                    chartKey: null,
                } : {}),
                ...(name === ENTITY_FIELD_NAME.SELECTED_EMPLOYEE_TYPE ? {
                    [isRuleEntity ? ENTITY_FIELD_NAME.SELECTED_RULE_USERS : ENTITY_FIELD_NAME.SELECTED_CHART_USERS]: selectedUsers,
                } : {}),
            },
        });
    };

    const onSaveRule = (payload) => {
        if ((state.selectedRuleInstructions || '').length > 2000) {
            ModalUtils.errorMessage(null, 'No more than 2000 characters allowed in the instructions');
            return;
        }

        if (payload) {
            saveRule({
                variables: payload,
            });
            return;
        }

        saveRule({
            variables: {
                id: state.selectedRule,
                categoryId: state.selectedRuleCategory,
                label: state.selectedRuleLabel,
                query: state.selectedRuleQuery,
                instructions: state.selectedRuleInstructions,
            },
        });
    };

    const onSaveChart = (payload) => {
        if (payload) {
            saveChart({
                variables: payload,
            });
            return;
        }

        const input = {
            id: state.selectedChart,
            categoryId: state.selectedChartCategory,
            label: state.selectedChartLabel,
            query: state.selectedChartQuery,
            type: state.selectedChartType,
            options: JSON.stringify(state.selectedChartOptions),
            customReport: state.isCustomReport,
        };

        if (state.frequency && state.startOn) {
            const startOn = moment(state.startOn);
            let hour = state.timeHour;

            // Convert hour to 24-hour format
            if (state.timeAmPm === Period.PM && hour !== 12) {
                hour += 12;
            } else if (state.timeAmPm === Period.AM && hour === 12) {
                hour = 0;
            }

            startOn.hour(hour);
            startOn.minute(state.timeMinute);

            input.frequency = state.frequency;
            input.startOn = startOn;
        }

        saveChart({ variables: input });
    };

    const filteredUsers = state.users
        .filter((user) => !StringUtils.isEmpty(user.employeeType)
            && !['Integration', 'Other'].includes(user.employeeType)
            && state.assignedUsers.includes(user.userId))
        .sort((a, b) => a.firstName.localeCompare(b.firstName));
    const assignedUsersInEntities = filteredUsers.map((user) => ({
        value: user.userId,
        label: StringUtils.toPascalCase(`${user.firstName?.toLowerCase()} ${user.lastName?.toLowerCase()} (${user.employeeType})`),
    }));

    const copyResource = (id) => {
        const { selectedCategory } = state;

        copyGlobalResource({
            variables: {
                chartId: id,
                categoryId: selectedCategory,
                isCustomReport: isCustomReports,
            },
        });
    };

    const openResource = (id) => {
        const chart = state.globalCharts.find((gc) => gc.id === id);
        if (chart) {
            const {
                label,
                query,
                type: chartType,
                options,
            } = chart;

            getGoals();
            pullQueryData({
                variables: {
                    statement: query,
                },
            });

            dispatch({
                type: ACTION_TYPES.UPDATE_FIELDS,
                value: {
                    isEntityDialogOpen: true,
                    selectedChartCategory: state.selectedCategory,
                    selectedGoalCategory: state.selectedCategory,
                    selectedChart: null,
                    selectedChartLabel: label,
                    selectedChartQuery: query,
                    selectedChartType: chartType,
                    selectedChartOptions: JSON.parse(options),
                    selectedChartLoadedColumns: [],
                    selectedChartUsers: [],
                    isCustomReport: isCustomReports,
                    chartKey: TableUtils.generateUUID(),
                },
            });
        }
    };

    const onGlobalResourceSearch = (input) => {
        dispatch({
            type: ACTION_TYPES.SET_GLOBAL_SEARCH,
            value: input,
        });
    };

    const renderGlobalResources = () => (
        <div className={classes.container}>
            <div className={classes.label}>
                Global Resources
            </div>
            <Search className={classes.globalSearch} fullWidth onKeyDown={(input) => onGlobalResourceSearch(input)} />
            <div className={clsx(classes.list, classes.globalList)}>
                {(state.globalCharts ?? []).map(({
                    id,
                    label: chartLabel,
                    type: chartType,
                }, index) => (
                    <div className={classes.globalListItem} key={index}>
                        <div>{`${index + 1}. ${chartLabel} | ${chartType}`}</div>
                        <div>
                            <Tooltip title="Copy">
                                <span>
                                    <Button
                                        size="small"
                                        disabled={!state.selectedCategory || copyingGlobalResource}
                                        className={classes.containedSecondaryInfo}
                                        startIcon={<FileCopyIcon />}
                                        onClick={() => copyResource(id)}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title="Open">
                                <span>
                                    <Button
                                        size="small"
                                        disabled={copyingGlobalResource}
                                        className={classes.containedSecondaryInfo}
                                        startIcon={<AddBoxIcon />}
                                        onClick={() => openResource(id)}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    const renderList = (section) => {
        let list = [];
        let selected = null;
        const { selectedAssignedUser } = state;

        switch (section) {
        case ENTITY_SECTION.CATEGORIES:
            list = StringUtils.isEmpty(state.categoriesSearch)
                ? state.categories?.map((cat) => ({ ...cat, name: cat.name?.toUpperCase() }))
                : state.categories?.filter((cat) => cat.name.toLowerCase().includes(state.categoriesSearch.toLowerCase()))
                    .map((cat) => ({ ...cat, name: cat.name?.toUpperCase() }));
            selected = state.selectedCategory;
            break;
        case ENTITY_SECTION.RULES:
            selected = state.selectedRule;
            if (state.selectedCategory) {
                list = state.rules.filter((rule) => (
                    rule.category.id === state.selectedCategory
                    && (
                        !selectedAssignedUser
                        || rule.assignedUsers.findIndex((u) => u.userId === selectedAssignedUser) > 0
                    )
                ));
                if (!StringUtils.isEmpty(state.entitySearch)) list = list.filter((rule) => rule.label.toLowerCase().includes(state.entitySearch.toLowerCase()));
            }
            break;
        case ENTITY_SECTION.CHARTS:
            selected = state.selectedChart;
            if (state.selectedCategory) {
                list = state.charts.filter((chart) => (
                    chart.category.id === state.selectedCategory
                    && (
                        !selectedAssignedUser
                        || chart.assignedUsers.findIndex((u) => u.userId === selectedAssignedUser) > 0
                    )
                ));
                if (!StringUtils.isEmpty(state.entitySearch)) {
                    list = list.filter((chart) => chart.label.toLowerCase().includes(state.entitySearch.toLowerCase()));
                }
            }
            break;
        default:
            break;
        }

        const isAddDisabled = [ENTITY_SECTION.RULES, ENTITY_SECTION.CHARTS].includes(section) && !state.selectedCategory;
        return (
            <div className={classes.container}>
                <div className={classes.label}>
                    {section}
                    {canWrite && (
                        <Button
                            className={isAddDisabled ? classes.addDisabled : ''}
                            disabled={isAddDisabled}
                            size="small"
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={() => toggleCreationDialog(section)}
                        />
                    )}
                </div>
                <div className={classes.boxSearch}>
                    <Search fullWidth onKeyDown={(input) => actionFilter(section, input)} />
                    {section !== ENTITY_SECTION.CATEGORIES && (
                        <div>
                            <Select
                                nowrap
                                size="sm"
                                loading={false}
                                name="selectedAssignedUser"
                                className={classes.input}
                                onChange={(name, value) => onChange({ target: { name, value } })}
                                value={state.selectedAssignedUser ?? ''}
                                options={assignedUsersInEntities}
                            />
                            <Button
                                size="small"
                                className={classes.containedSecondaryInfo}
                                onClick={() => onChange({ target: { name: 'selectedAssignedUser', value: null } })}
                            >
                                Clear
                            </Button>
                            <Tooltip title="Remove the selected user from all reports">
                                <Button
                                    size="small"
                                    className={classes.containedError}
                                    onClick={() => (state.selectedAssignedUser ? toggleDeletePrompt('users') : null)}
                                >
                                    {isMobile ? 'Remove' : 'Remove User'}
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div className={classes.list}>
                    {list.map((element, index) => {
                        const isSelected = selected === element.id;

                        return (
                            <div
                                className={isSelected ? classes.listItemSelected : classes.listItem}
                                onClick={() => setSelectedElement(section, element.id)}
                                key={index}
                            >
                                <span>{element.name || element.label}</span>
                                {isSelected && canWrite && (
                                    <div>
                                        <Tooltip title="Edit">
                                            <span>
                                                <Button
                                                    size="small"
                                                    startIcon={<EditOutlinedIcon />}
                                                    onClick={() => toggleCreationDialog(section, true)}
                                                />
                                            </span>
                                        </Tooltip>
                                        {[ENTITY_SECTION.RULES, ENTITY_SECTION.CHARTS].includes(section) && (
                                            <Tooltip title="Duplicate">
                                                <span>
                                                    <Button
                                                        size="small"
                                                        startIcon={<FileCopyOutlinedIcon />}
                                                        onClick={toggleClonePrompt}
                                                    />
                                                </span>
                                            </Tooltip>
                                        )}
                                        <Tooltip title="Delete">
                                            <span>
                                                <Button
                                                    size="small"
                                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                                    onClick={() => toggleDeletePrompt(section)}
                                                />
                                            </span>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const categoryDialogInitValue = state.selectedCategory
        ? state.categories.find((cat) => cat.id === state.selectedCategory)?.name
        : '';

    return (
        <>
            {isMobile && (
                <Grid container className={classes.box}>
                    {renderList(ENTITY_SECTION.CATEGORIES)}
                    {renderList(isRuleEntity ? ENTITY_SECTION.RULES : ENTITY_SECTION.CHARTS)}
                    {!isRuleEntity && renderGlobalResources()}
                </Grid>
            )}
            {!isMobile && (
                <Grid container className={classes.box}>
                    <Split
                        sizes={isRuleEntity ? [20, 80] : [20, 50, 30]}
                        minSize={0}
                        gutterSize={10}
                        gutterAlign="center"
                        direction="horizontal"
                        cursor="col-resize"
                        className={classes.splitter}
                    >
                        {renderList(ENTITY_SECTION.CATEGORIES)}
                        {renderList(isRuleEntity ? ENTITY_SECTION.RULES : ENTITY_SECTION.CHARTS)}
                        {!isRuleEntity && renderGlobalResources()}
                    </Split>
                </Grid>
            )}
            <AddEntityDialog
                classes={classes}
                isMobile={isMobile}
                canWrite={canWrite}
                chartKey={state.chartKey}
                isRuleEntity={isRuleEntity}
                isDialogOpen={state.isEntityDialogOpen}
                toggleDialog={() => toggleCreationDialog(isRuleEntity ? ENTITY_SECTION.RULES : ENTITY_SECTION.CHARTS)}
                onChange={onChange}
                onSaveEntity={isRuleEntity ? onSaveRule : onSaveChart}
                savingEntity={isRuleEntity ? savingRule : savingChart}
                getDataPreview={pullQueryData}
                loadingColumns={loadingColumns}
                loadingUsers={loadingUsers}
                categories={state.categories}
                goals={state.goals}
                users={state.users}
                selectedGoalCategory={state.selectedGoalCategory}
                selectedEntityLabel={isRuleEntity ? state.selectedRuleLabel : state.selectedChartLabel}
                selectedEntityQuery={isRuleEntity ? state.selectedRuleQuery : state.selectedChartQuery}
                selectedEntityCategory={isRuleEntity ? state.selectedRuleCategory : state.selectedChartCategory}
                selectedEntityInstructions={state.selectedRuleInstructions}
                selectedEntityType={state.selectedChartType}
                selectedEntityOptions={state.selectedChartOptions}
                selectedEntityLoadedColumns={state.selectedChartLoadedColumns}
                selectedChartExtraOptions={state.selectedChartExtraOptions}
                selectedEntityUsers={isRuleEntity ? state.selectedRuleUsers : state.selectedChartUsers}
                selectedChart={state.selectedChart}
                isCustomReport={state.isCustomReport}
                scheduleReport={{
                    frequency: state.frequency,
                    timeHour: state.timeHour,
                    timeMinute: state.timeMinute,
                    timeAmPm: state.timeAmPm,
                    startOn: state.startOn,
                }}
                onChangeScheduleReport={onChangeScheduleReport}
                onChangeFrequency={onChangeFrequency}
            />
            {state.isCategoryPanelOpen && (
                <AddCategoryDialog
                    initialValue={categoryDialogInitValue}
                    isSavingCategory={savingCategory}
                    onClose={() => toggleCreationDialog(ENTITY_SECTION.CATEGORIES)}
                    onSaveCategory={onSaveCategory}
                />
            )}
            {state.isClonePanelOpen && (
                <CloneEntityDialog
                    isRuleEntity={isRuleEntity}
                    entity={isRuleEntity ? state.rules.find((el) => el.id === state.selectedRule) : state.charts.find((el) => el.id === state.selectedChart)}
                    isSavingEntity={isRuleEntity ? savingRule : savingChart}
                    onClose={toggleClonePrompt}
                    onSaveEntity={isRuleEntity ? onSaveRule : onSaveChart}
                />
            )}
            <ConfirmDialog
                title="Attention!"
                description={state.deleteDescription}
                open={state.isDeletePromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={onDeleteAction}
                disablePrimaryButton={savingRule || savingChart || deletingCategory}
                disableSecondaryButton={savingRule || savingChart || deletingCategory}
            />
        </>
    );
};

EntitySettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
};

export default EntitySettings;
