import { gql } from '@apollo/client';

export default class VendorQuery {
    static GET_VENDOR_LIST = gql`
        query getVendorList($filter: VendorListInput, $sort: DataSort) {
            getVendorList(filter: $filter, sort: $sort) {
                vendorId
                vendorName
                vendorType
                vendorAddress
                vendorFee
                floorRate
                is1099
            }
        }
    `;

    static GET_SETTINGS_VENDOR_LIST = gql`
        query getVendorSettingsList($paginate: DataPaging, $filter: VendorSettingsFilter, $sort: DataSort) {
            getVendorSettingsList(paginate: $paginate, filter: $filter, sort: $sort, ) {
                totalCount
                data {
                    vendorId
                    vendorName
                    vendorCode
                    vendorType
                    vendorAddress
                    vendorCity
                    vendorState
                    provider
                    active
                    is1099
                }
            }
        }
    `;

    static VENDOR_DETAIL = gql`
        query getVendorDetail($vendorId: Int) {
            getVendorDetail(vendorId: $vendorId) {
                vendorId
                vendorName
                vendorAddress
                vendorZip
                vendorCity
                vendorState
                vendorPhone
                vendorFax
                vendorContact
                vendorFednid
                vendorCrNumber
                reservePercentage
                vendorFee
                floorRate
                vendorType
                vendorFormula
                vendorNotes
                active
                is1099
                curtailmentDays
                curtailmentPercentage
                curtailmentDueDays
                discountFormula
                provider
                menuVendordetail {
                    menuVendorDetailId
                    providerCompanyId
                    companyCode
                    dealerCode
                    lotCodeList {
                        lot
                        code
                    }
                    userName
                    password
                    allowVoidContracts
                    allowMultipleContractsSubmit
                }
            }
        }
    `;

    static GET_PRODUCT_VENDOR_LIST = gql`
        query getVendorList($filter: VendorListInput, $sort: DataSort) {
            getVendorList(filter: $filter, sort: $sort) {
                vendorId
                vendorName
                provider
            }
        }
    `;

    static GET_VENDOR_CATEGORIES = gql`
        query getVendorCategories($sort: DataSort) {
            getVendorCategories(sort: $sort) {
                vendorCategoriesId
                category
            }
        }
    `;
}
