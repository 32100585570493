import React, { useEffect, useContext } from 'react';
import {
    Grid,
    makeStyles,
    FormControl,
    Button,
} from '@material-ui/core';
import { INVOICE_STATUS_LIST, SERVICE_JOBS_STATUS_LIST } from 'utils/enum/ServiceInvoiceEnum';
import UserContext from 'components/context/UserContext';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import If from 'components/widgets/conditional/If';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import useTechnicianJobs from 'components/modules/service/serviceInvoice/hooks/useTechnicianJobs';
import TechnicianJobForm from 'components/modules/service/serviceInvoice/create/TechnicianJobForm';
import LotFilter from 'components/modules/inventory/list/LotFilter';
import InputSearch from 'components/widgets/InputSearch';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const ownStyle = makeStyles((theme) => (
    {
        root: {
            overflow: 'auto',
            width: '100%',
            height: 'calc(100vh - 110px)',
            backgroundColor: `rgba(${theme.palette.rgb.black}, 0.02)`,
        },
        search: {
            width: 'auto',
            borderRadius: '10px',
            marginTop: '3px',
            marginBottom: '3px',
            '& > div': {
                width: '230px',
                flex: 'initial',
                marginRight: '25px',
                [theme.breakpoints.down(500)]: {
                    width: '200px',
                    marginRight: 0,
                },
            },
            '& .form-control': {
                height: 'calc(1.5em + 0.5rem + 2px)',
                padding: '0.25rem 0.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.5',
                borderRadius: '0.2rem',
            },
        },
        topBar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '10px',
            paddingRight: '20px',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                '& > div:nth-child(1)': {
                    marginBottom: '10px',
                },
            },
            '& > div:nth-child(2)': {
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                    flexWrap: 'wrap',
                    padding: '5px',
                    height: '80px',
                },
                [theme.breakpoints.down(500)]: {
                    height: '190px',
                    flexWrap: 'nowrap',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                },
                '& > button': {
                    marginRight: '5px',
                    [theme.breakpoints.down(500)]: {
                        width: '200px',
                        marginRight: 0,
                    },
                },
                '& > div:nth-child(2), & > div:nth-child(3), & > div:nth-child(4)': {
                    width: '200px !important',
                    marginRight: '5px',
                },
            },
        },
    }
));

const TechnicianJobList = () => {
    const { userInformation = {} } = useContext(UserContext);
    const classes = { ...ownStyle(), ...buttonStyles() };
    const {
        state,
        loading,
        loadMore,
        usersData,
        loadingUsers,
        onSearch,
        onChangeTechnician,
        onFilterByLot,
        selectedLots,
        reconCategories,
        onRefreshJobs,
        onChangeStatus,
    } = useTechnicianJobs();
    const { table, technicians, params: { search } } = state;
    const { records, totalCount } = table;
    const saving = false;

    useEffect(() => {
        usersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (technicians.length > 0 && Object.keys(userInformation).length > 0) {
            const currentUserId = userInformation.userId;
            const isTechnician = technicians.some((t) => t.userId === currentUserId);
            onChangeTechnician(isTechnician ? currentUserId : -1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [technicians, userInformation]);

    return (
        <>
            <div className={classes.topBar}>
                <div>
                    <InputSearch
                        customClasses={classes.search}
                        initialSearch={search || ''}
                        onSearch={onSearch}
                    />
                </div>
                <div>
                    <Button
                        disabled={loading}
                        className={classes.containedGreen}
                        size="small"
                        onClick={() => onRefreshJobs()}
                    >
                        Refresh
                    </Button>
                    <FormControl>
                        <Select
                            name="roStatus"
                            onChange={(name, newValue) => onChangeStatus(name, newValue)}
                            value={state.roStatus}
                            options={[
                                { label: 'All RO Statuses', value: 'All RO Statuses' },
                                ...INVOICE_STATUS_LIST,
                            ]}
                            nowrap
                            size="sm"
                            loading={false}
                        />
                    </FormControl>
                    <FormControl>
                        <Select
                            name="jobStatus"
                            onChange={(name, newValue) => onChangeStatus(name, newValue)}
                            value={state.jobStatus}
                            options={[
                                { label: 'All Job Statuses', value: 'All Job Statuses' },
                                ...SERVICE_JOBS_STATUS_LIST,
                            ]}
                            nowrap
                            size="sm"
                            loading={false}
                        />
                    </FormControl>
                    <FormControl>
                        <Select
                            name="technician"
                            onChange={(_, newValue) => onChangeTechnician(newValue)}
                            value={state.selectedTechnician}
                            options={[
                                { userId: -1, firstName: 'All', lastName: 'Technicians' },
                                { userId: -2, firstName: 'Unassigned', lastName: 'Technicians' },
                                ...technicians,
                            ].map((user) => ({
                                value: user.userId,
                                label: `${user.firstName} ${user.lastName}`,
                            }))}
                            nowrap
                            size="sm"
                            loading={loadingUsers}
                        />
                    </FormControl>
                    {selectedLots != null && (
                        <LotFilter onSearch={onFilterByLot} initialLots={selectedLots} />
                    )}
                </div>
            </div>
            <InfiniteScroll
                className={classes.root}
                lengthRecord={records.length}
                totalRecord={totalCount}
                loadMore={loadMore}
                load={loading}
                loadAtScrollPercent={90}
            >
                <Grid container>
                    {
                        records.map((item) => (
                            <Grid key={`technician-item-${item.serviceJobId}`} item xs={12}>
                                <TechnicianJobForm
                                    item={item}
                                    reconCategories={reconCategories ?? []}
                                    technicians={technicians}
                                    loadingUsers={loadingUsers}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </InfiniteScroll>
            <If condition={saving}>
                <DialogActionMessage message="saving..." />
            </If>
        </>
    );
};

export default TechnicianJobList;
