import React, {
    useReducer, useEffect, useContext, useCallback,
} from 'react';
import Container from 'components/widgets/Container';
import {
    Grid,
    makeStyles, Button, Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography, Slide, Chip,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LoadingMask from 'components/widgets/LoadingMask';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import { SaveIcon, CloseIcon } from 'components/icons';
import { Row, Form, Col } from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import CashierPaymentSchema from 'utils/schema/accounting/accountReceivable/CashierPaymentSchema';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { useLazyQuery, useMutation } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import DialogActions from '@material-ui/core/DialogActions';
import DateUtils from 'lib/DateUtils';
import CatalogSelect from 'components/widgets/catalogs/CatalogSelect';
import UserContext from 'components/context/UserContext';
import NumberUtils from 'lib/NumberUtils';
import { useHistory, useParams } from 'react-router';
import { modules } from 'utils/enum/modules';
import { FetchPolicy } from 'utils/enum/Core';
import AccountingUtils from 'utils/AccountingUtils';
import {
    AccountingSubModules, AccountsReceivableEntities,
    CashierDirectionOperation, CashierTakePaymentStatus, AccountReceivableTransactionType,
} from 'utils/enum/AccountingEnum';
import AccountReceivableMutation from 'services/graphQL/mutate/accounting/AccountReceivableMutation';
import { isEmpty } from 'lodash';
import AccountReceivablesMapper from 'services/mapData/AccountReceivablesMapper';
// todo: check why the datepicker doesn't work
import 'react-datepicker/dist/react-datepicker.css';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import DropdownQuery from 'components/widgets/DropdownQuery';

const keyStore = new KeyStore();

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_RECORD_VALUE: 'setRecordValue',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
};

const CashierPaymentReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return {
            ...state,
            record: action.value,
            isNew: action.value.transactionNumber === 0,
            customFieldData: action.customFieldData || state.customFieldData,
            depositedValues: action.depositedValues || state.depositedValues,
        };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        const { value, isDirty } = action;
        return { ...state, record: value, isDirty };
    }
    case ACTION_TYPES.SET_RECORD_VALUE: {
        return { ...state, [action.value.key]: action.value.value };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

const CashierPaymentCreate = () => {
    const ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS);

    const { availableLots, defaultLot, defaultLotId } = useContext(UserContext);
    const overrideAvailableLots = (availableLots || []).map((item) => ({ lotId: item.id, lotName: item.label }));

    const { entity, accountId, id } = useParams();
    const history = useHistory();

    const comeFromOtherCollection = entity === AccountsReceivableEntities.OTHER_COLLECTIONS;
    const comeFromPettyCash = entity === AccountsReceivableEntities.PETTY_CASH;

    const initialState = {
        record: {
            transactionNumber: 0,
            accountNumber: 0,
            transactionType: '',
            reason: '',
            cash: 0,
            check: 0,
            charge: 0,
            cashiersCheck: 0,
            dateNTime: new Date(),
            memo: '',
            customer: '',
            lotName: defaultLot === 'null' || defaultLot.length === 0 ? null : defaultLot,
            lotId: defaultLotId,
            direction: comeFromPettyCash ? CashierDirectionOperation.OUT : CashierDirectionOperation.IN,
            status: CashierTakePaymentStatus.NEW,
            customField: 0,
        },
        isDirty: false,
        isNew: true,
        couldLostData: false,
        openTakePayment: false,
        customFieldData: {
            displayCustomField: false,
            customFieldName: '',
        },
        depositedValues: [],
    };

    const classes = useStyle();

    const [state, dispatch] = useReducer(CashierPaymentReducer, initialState);
    const {
        record, isDirty, couldLostData, customFieldData, depositedValues,
    } = state;

    const isValidData = isValidSchema(CashierPaymentSchema, record);
    const { isValid, errors } = isValidData;

    const [getCashierPayment, { loading }] = useLazyQuery(AccountReceivableQuery.GET_CASHIER_PAYMENT, {
        onCompleted: (res) => {
            if (res.getCashierPayment) {
                const result = { ...res.getCashierPayment };
                result.dateNTime = DateUtils.toLocal(result.dateNTime).toDate();

                if (result.voided) result.status = CashierTakePaymentStatus.VOIDED;
                else if (isEmpty(result.status)) result.status = CashierTakePaymentStatus.ACTIVE;

                let customFieldSettings = null;
                if (result.transactionType?.toLowerCase() !== AccountReceivableTransactionType.PETTY_CASH.toLowerCase()) {
                    const { customFieldData: data } = result;
                    customFieldSettings = {
                        displayCustomField: data.displayCustomField,
                        customFieldName: data.customFieldName,
                    };
                }

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...result,
                    },
                    customFieldData: customFieldSettings,
                    depositedValues: result.depositedValues,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getCashierCustomField, { loading: loadingCustomField }] = useCallback(useLazyQuery(AccountReceivableQuery.GET_CASHIER_CUSTOM_FIELD, {
        onCompleted: (res) => {
            if (res.getCashierCustomField) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD_VALUE,
                    value: {
                        key: 'customFieldData',
                        value: res.getCashierCustomField,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    }), []);

    const onClearForm = () => {
        const currentRecord = { ...record };
        const initialRecord = initialState.record;

        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: {
                ...initialState,
                record: {
                    ...initialRecord,
                    accountNumber: currentRecord.accountNumber,
                    transactionNumber: currentRecord.transactionNumber,
                    customer: ' ',
                },
            },
        });
    };

    const onPopupClose = (event, forceClose = false) => {
        if (isDirty && !forceClose) {
            dispatch(
                {
                    type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
                },
            );
            return;
        }

        onClearForm();
        history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/${entity}`);
    };

    const mutationQuery = record.transactionNumber > 0 ? AccountReceivableMutation.UPDATE_CASHIER_PAYMENT : AccountReceivableMutation.CREATE_CASHIER_PAYMENT;
    const [addRecord, { loading: isSaving }] = useMutation(mutationQuery, {
        onCompleted: (mutationData) => {
            if (mutationData?.createCashierPayment || mutationData?.updateCashierPayment) {
                ModalUtils.successMessage(null, 'Successfully saved!');

                onPopupClose(null, true);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = async () => {
        const recordToSave = AccountReceivablesMapper.mapTakePaymentToSave(record);

        await addRecord({
            variables: {
                record: recordToSave,
            },
        });
    };

    const onChangeValue = (field, value) => {
        let checkDirty = true;

        if (comeFromPettyCash
            && !Number.isNaN(value)
            && Math.abs(value) === Math.abs(record[field])) checkDirty = false;

        if (value === record[field]) return;

        const currentRecord = { ...record };

        currentRecord[field] = value;

        if (field === 'lotId' && defaultLotId === 0) {
            currentRecord.lotName = overrideAvailableLots.find((c) => c.lotId === value)?.lotName;
        }

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
            isDirty: checkDirty,
        });
    };

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_RECORD,
            value: {
                ...record,
                transactionNumber: Number(id ?? 0),
                accountNumber: Number(accountId ?? 0),
                transactionType: AccountingUtils.getTransactionType(entity),
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (record.transactionNumber > 0) {
            getCashierPayment({
                variables: {
                    transactionNumber: record.transactionNumber,
                },
            });
        }
    }, [entity, getCashierPayment, record.transactionNumber]);

    useEffect(() => {
        const transactionType = AccountingUtils.getTransactionType(entity);
        if (record.transactionNumber === 0
            && transactionType.toLowerCase() !== AccountReceivableTransactionType.PETTY_CASH.toLowerCase()) {
            getCashierCustomField();
        }
    }, [entity, getCashierCustomField, record.transactionNumber]);

    const getTitleForm = () => {
        if (record.transactionNumber === 0 && comeFromPettyCash) return 'New Payout';
        if (record.transactionNumber > 0 && comeFromPettyCash) return `Edit Payout ${record.transactionNumber}`;
        if (record.transactionNumber > 0) return `Edit Payment ${record.transactionNumber}`;
        return 'New Payment';
    };

    const getValidForm = () => {
        // eslint-disable-next-line max-len
        if ([CashierTakePaymentStatus.VOIDED.toLowerCase(), CashierTakePaymentStatus.DEPOSITED.toLowerCase()].includes(record.status.toLowerCase())) return false;

        if (isValid && record.cash + record.check + record.charge + record.cashiersCheck + record.customField !== 0
        && isDirty && !comeFromOtherCollection && !comeFromPettyCash && !isSaving) return true;
        if (isValid && !isEmpty(record.customer) && (comeFromOtherCollection || comeFromPettyCash) && isDirty && !isSaving) return true;
        return false;
    };

    const getStatusChip = () => {
        if (record.status.toLowerCase() === CashierTakePaymentStatus.NEW.toLowerCase()) return null;

        let badgeStatus = classes.primary;
        if (record.status.toLowerCase() === CashierTakePaymentStatus.DEPOSITED.toLowerCase()
        || record.status.toLowerCase() === CashierTakePaymentStatus.PARTIALLY_DEPOSIT.toLowerCase()) badgeStatus = classes.badgeApproved;
        else if (record.status.toLowerCase() === CashierTakePaymentStatus.VOIDED.toLowerCase()) badgeStatus = classes.badgeVoid;

        return (
            <>
                <span className={classes.fontSmall}>Status: </span>
                <Chip size="small" label={record.status} className={badgeStatus} />
            </>
        );
    };

    const disableControls = record.status !== CashierTakePaymentStatus.ACTIVE
    && record.status !== CashierTakePaymentStatus.NEW;

    const disabledPaymentMethod = (item) => (record.status.toLowerCase() !== CashierTakePaymentStatus.DEPOSITED.toLowerCase()
        && depositedValues.length > 0
        ? depositedValues.includes(item)
        : disableControls
    );

    return (
        <Dialog
            open
            onClose={onPopupClose}
            maxWidth="xs"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <Typography variant="h6" className={classes.title}>
                        { getTitleForm()}
                    </Typography>
                    <div className={classes.centerItems}>
                        <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.noPadding}>
                { (loading || loadingCustomField) ? <LoadingMask />
                    : (
                        <Container className={classes.containerSplit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={4} className="required">Cash</Form.Label>
                                        <Col xs={8}>
                                            <InputNumber
                                                size="sm"
                                                showCurrency
                                                value={record.cash}
                                                className={record.cash + record.check + record.charge
                                                    + record.cashiersCheck + record.customField === 0 ? 'invalid-field' : ''}
                                                thousandSeparator
                                                placeholder="Cash"
                                                onChange={(value) => onChangeValue('cash', value)}
                                                disabled={disabledPaymentMethod('cash')}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {!comeFromPettyCash
                                    && (
                                        <>
                                            <Form.Group as={Row}>
                                                <Form.Label column="sm" xs={4} className="required">Check</Form.Label>
                                                <Col xs={8}>
                                                    <InputNumber
                                                        size="sm"
                                                        showCurrency
                                                        value={record.check}
                                                        className={record.cash + record.check + record.charge
                                                            + record.cashiersCheck + record.customField === 0 ? 'invalid-field' : ''}
                                                        thousandSeparator
                                                        placeholder="Check"
                                                        onChange={(value) => onChangeValue('check', value)}
                                                        disabled={disabledPaymentMethod('check')}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column="sm" xs={4} className="required">Charge</Form.Label>
                                                <Col xs={8}>
                                                    <InputNumber
                                                        size="sm"
                                                        showCurrency
                                                        value={record.charge}
                                                        className={record.cash + record.check + record.charge
                                                    + record.cashiersCheck + record.customField === 0 ? 'invalid-field' : ''}
                                                        thousandSeparator
                                                        placeholder="Charge"
                                                        onChange={(value) => onChangeValue('charge', value)}
                                                        disabled={disabledPaymentMethod('charge')}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column="sm" xs={4} className="required">Cashier`s check</Form.Label>
                                                <Col xs={8}>
                                                    <InputNumber
                                                        size="sm"
                                                        showCurrency
                                                        value={record.cashiersCheck}
                                                        className={record.cash + record.check + record.charge
                                                            + record.cashiersCheck + record.customField === 0 ? 'invalid-field' : ''}
                                                        thousandSeparator
                                                        placeholder="Cashier check"
                                                        onChange={(value) => onChangeValue('cashiersCheck', value)}
                                                        disabled={disabledPaymentMethod('cashiersCheck')}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            { (customFieldData.displayCustomField || record.customField > 0) && (
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" xs={4} className="required">
                                                        {customFieldData.customFieldName}
                                                    </Form.Label>
                                                    <Col xs={8}>
                                                        <InputNumber
                                                            size="sm"
                                                            showCurrency
                                                            value={record.customField}
                                                            className={record.cash + record.check + record.charge
                                                                + record.cashiersCheck + record.customField === 0 ? 'invalid-field' : ''}
                                                            thousandSeparator
                                                            placeholder={customFieldData.customFieldName}
                                                            onChange={(value) => onChangeValue('customField', value)}
                                                            disabled={disabledPaymentMethod('customField')}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            )}
                                            <Form.Group as={Row}>
                                                <Form.Label column="sm" xs={4}>Total Collected</Form.Label>
                                                <Col xs={8}>
                                                    <Form.Label column="sm" xs={8}>
                                                        {NumberUtils.applyCurrencyFormat(record.cash + record.check
                                                    + record.charge + record.cashiersCheck + record.customField)}
                                                    </Form.Label>
                                                </Col>
                                            </Form.Group>
                                        </>
                                    )}
                                    {(defaultLotId === 0)
                                    && (
                                        <Form.Group as={Row}>
                                            <Form.Label column="sm" xs={4} className="required">Lot</Form.Label>
                                            <Col xs={8}>
                                                <DropdownQuery
                                                    name="lotId"
                                                    className={isValidField(errors, 'lotName') ? 'invalid-field' : ''}
                                                    value={record.lotId}
                                                    placeHolder="select a lot"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                    dataSource={{
                                                        localData: overrideAvailableLots,
                                                        idField: 'lotId',
                                                        descriptionField: 'lotName',
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={4} className="required">Reason *</Form.Label>
                                        <Col xs={8}>
                                            <CatalogSelect
                                                catalogEnum={AccountingUtils.getCatalogReason(entity)}
                                                catalogTitle="Account Receivable - Descriptions For Deals"
                                                name="reason"
                                                className={isValidField(errors, 'reason') ? 'invalid-field' : ''}
                                                value={record.reason}
                                                placeHolder="select the reason"
                                                onChange={(name, newValue) => onChangeValue('reason', newValue)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {comeFromOtherCollection
                                    && (
                                        <Form.Group as={Row}>
                                            <Form.Label column="sm" xs={4} className="required">Control Number</Form.Label>
                                            <Col xs={8}>
                                                <InputNumber
                                                    size="sm"
                                                    value={record.accountNumber}
                                                    className={isValidField(errors, 'accountNumber') ? 'invalid-field' : ''}
                                                    placeholder="Control Number"
                                                    onChange={(value) => onChangeValue('accountNumber', value)}
                                                    disabled={disableControls}
                                                />
                                            </Col>
                                        </Form.Group>
                                    )}
                                    {(comeFromOtherCollection || comeFromPettyCash)
                                    && (
                                        <Form.Group as={Row}>
                                            <Form.Label column="sm" xs={4} className="required">Customer`s Name *</Form.Label>
                                            <Col xs={8}>
                                                <Form.Control
                                                    className={(isEmpty(record.customer)
                                                        && (comeFromOtherCollection || comeFromPettyCash)) ? 'invalid-field' : ''}
                                                    size="sm"
                                                    value={record.customer}
                                                    placeholder="Customer"
                                                    onChange={(e) => onChangeValue('customer', e.target.value)}
                                                    disabled={disableControls}
                                                />
                                            </Col>
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={4} className="required">Memo</Form.Label>
                                        <Col xs={8}>
                                            <Form.Control
                                                as="textarea"
                                                className={isValidField(errors, 'memo') ? 'invalid-field' : ''}
                                                size="sm"
                                                rows="3"
                                                value={record.memo || ''}
                                                placeholder="Memo"
                                                onChange={(e) => onChangeValue('memo', e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Grid>
                            </Grid>
                            <ConfirmDialog
                                title="Attention - You have unsaved changes!"
                                description="Do you want to close without saving?"
                                open={couldLostData}
                                variant="outlined"
                                titlePrimary="Close"
                                titleSecondary="Cancel"
                                onClose={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                                onClickSecondary={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                                onClickPrimary={(e) => onPopupClose(e, true)}
                            />
                        </Container>
                    )}
            </DialogContent>
            <DialogActions className={classes.borderTop}>
                <div className={classes.fullWidth}>
                    <Toolbar className={classes.centerItems}>
                        <div className={classes.buttonSpacing}>
                            { getStatusChip() }
                        </div>
                        {ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS
                        && (
                            <div className={classes.buttonSpacing}>
                                <Button
                                    className={classes.buttonSave}
                                    startIcon={<SaveIcon />}
                                    size="small"
                                    disabled={!getValidForm()}
                                    onClick={onSave}
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </Toolbar>
                </div>
            </DialogActions>
            {isSaving && <DialogActionMessage message="Saving information... " />}
        </Dialog>
    );
};

export default CashierPaymentCreate;
