import { gql } from '@apollo/client';

export default class AccountReceivableQuery {
  static GET_UNPAID_DEAL_LIST = gql`
    query getUnpaidDealList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: AccountReceivableDealFilter
    ) {
        getUnpaidDealList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
            accountNumber
            cashDownPayment
            balanceDue
            tags
            title
            registration
            soldDate
            salespersonId1
            salesPerson1
            financeManagerId
            financeManager
            buyerCode
            buyer
            dealType
            term
            paymentAmount
            monthlyLeaseTax
            firstPaymentDue
            financeCompany
            amountDue
            collectedAmount
            status
        }
      }
    }
  `;

static GET_CASHIER_PAYMENT_BY_PROCESS_LIST = gql`
query getCashierPaymentList(
  $paginate: PaginationInput
  $sort: [SortField]
  $filter: AccountReceivableCashierPaymentFilter
) {
  getCashierPaymentList(paginate: $paginate, sort: $sort, filter: $filter) {
    totalCount
    data {
      transactionNumber
      accountNumber
      amount
      dateNTime
      reason
      memo
      userName
      lotName
      customer
      voided
      status
      voidReason
      transactionType
      isElectronic
      providerTransactionPaymentId
      electronicPaymentType
    }
  }
}
`;

static GET_CASHIER_PAYMENT = gql`
    query getCashierPayment(
      $transactionNumber: Int
    ) {
      getCashierPayment(transactionNumber: $transactionNumber) {
        transactionNumber
        accountNumber
        transactionType
        lotName
        lotId
        reason
        cash
        check
        charge
        cashiersCheck
        customField
        dateNTime
        customer
        memo
        direction
        status
        voided
        customFieldData {
          entityMetadataId
          displayCustomField
          customFieldName
          customFieldValue
        }
        depositedValues
      }
    }
  `;

static GET_UNPAID_SERVICE_LIST = gql`
    query getUnpaidServiceInvoiceList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: AccountReceivableServiceInvoiceFilter
    ) {
      getUnpaidServiceInvoiceList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          invoiceNumber
          customerCode
          customer
          advisor
          roClosed
          vehicleYear
          vehicleMake
          vehicleTrim
          vehicleModel
          balanceDue
          status
        }
      }
    }
  `;

static GET_CASHIER_MAPPING_CREDIT = gql`
query getCashierMappingCredit($key: String){
  getCashierMappingCredit(key: $key){
    reason
    type
    accountId
  }
}`

static GET_CASHIER_MAPPING_DEBIT = gql`
query getCashierMappingDebit{
  getCashierMappingDebit{
    cashAccountId
    checkAccountId
    chargeAccountId
  }
}`

static GET_CASHIER_OPEN_BALANCES = gql`
query getCashierOpenBalances(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: CashierCurrentDrawFilter
    ) {
      getCashierOpenBalances(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          direction
          cash
          check
          charge
          cashiersCheck
          customField
          amount
          dateNTime
          userName
          userPicture
        }
      }
    }
  `;

static GET_CASHIER_COLLECIONS = gql`
query getCashierOpenBalances(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: CashierCurrentDrawFilter
    ) {
      getCashierOpenBalances(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          direction
          transactionNumber
          reason
          amount
          dateNTime
          userName
          customer
          cashierData {
            accountNumber
            memo
          }
        }
      }
    }
  `;

static GET_CASHIER_COLLECIONS_BY_PAYMENT_TYPE = gql`
query getCashierOpenBalances(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: CashierCurrentDrawFilter
    ) {
      getCashierOpenBalances(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          cash
          check
          charge
          cashiersCheck
          customField
          cashierCurrentDrawId
          transactionNumber
          reason
          amount
          dateNTime
          userName
          customer
          cashierData {
            accountNumber
          }
        }
      }
    }
  `;

static GET_BATCHOUT_INFORMATION = gql`
query getBachoutInformation(
      $filter: CashierBatchOutFilter
    ) {
      getBachoutInformation(filter: $filter) {
        cashierBatchOutData {
          cashierCurrentDrawId
          transactionNumber
          customer
          reason
          amount
          dateNTime
          userName
          lotId
          lotName
          direction
          cash
          check
          charge
          cashiersCheck
          customField
          amount
          collectedType
          accountNumber
          transactionType
        }
        cashierMappingCredit {
          key
          reason
          type
          accountId
          isControlled
          controlledBy
          accountDescription
        }
        cashierMappingDebit {
          cashAccountId
          chargeAccountId
          checkAccountId
          customfieldAccountId
        }
      }
    }
  `;

static PRINT_SHIFT_COLLECTIONS_REPORT = gql`
query printShiftCollectionReport($userName: String, $direction: String, $printDate: String, $date: Date) {
      printShiftCollectionReport(userName: $userName, direction: $direction, printDate: $printDate, date: $date)
    }
  `;

static PRINT_CASHIER_RECEIPT = gql`
  mutation printCashierReceipt($transactionNumber: Int!, $printDate: String) {
    printCashierReceipt(transactionNumber: $transactionNumber, printDate: $printDate)
  }
`;

static GET_CASHIER_MAPPING_CUSTOM_FIELD = gql`
  query getCashierMappingCustomField{
    getCashierMappingCustomField{
      entityMetadataId
      displayCustomField
      customFieldName
      customFieldCreditAccountId
      customFieldDebitAccountId
    }
  }`

static GET_CASHIER_CUSTOM_FIELD = gql`
  query getCashierCustomField{
    getCashierCustomField{
      displayCustomField
      customFieldName
    }
  }`

  static GET_PAYMENT_PROCESS_INFORMATION = gql`
    query getPaymentProcessInformation($transactionType: String!, $accountNumber: Int!){
      getPaymentProcessInformation(transactionType: $transactionType, accountNumber: $accountNumber){
        customer
        vehicleInformation
        status
      }
    }
  `;

static GET_UNPAID_PARTS_LIST = gql`
  query getUnpaidPartsTicketList(
    $paginate: PaginationInput
    $sort: [SortField]
    $filter: AccountReceivablePartsFilter
  ) {
    getUnpaidPartsTicketList(paginate: $paginate, sort: $sort, filter: $filter) {
      totalCount
      data {
        ticketNumber
        ticketOpen
        ticketClosed
        balanceDue
        customer
        customerCode
        counterPerson
        lotName
        status
      }
    }
  }
`;

static GET_ELECTRONIC_PAYMENT = gql`
    query getElectronicPayment(
      $transactionNumber: String!
    ) {
      getElectronicPayment(transactionNumber: $transactionNumber) {
        cashierElectronicPaymentId
        accountNumber
        transactionType
        reason
        lotId
        lotName
        amount
        provider
        providerTransactionPaymentId
        orderIdentifier
        payloadRequest
        payloadResult
        resultCode
        resultMessages
        status
        memo
        waiveConvenienceFee
        convenienceFee
      }
    }
  `;
}
