import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Grid, Paper, Typography, Button,
    LinearProgress, Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DropdownQuery from 'components/widgets/DropdownQuery';
import { SERVICE_JOBS_STATUS_LIST } from 'utils/enum/ServiceInvoiceEnum';
import { Form } from 'react-bootstrap';
import { PartStatus } from 'utils/enum/InventoryEnum';
import { ReactComponent as PartOutlined } from 'assets/general/part.svg';
import { useMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import PartsRequest from 'components/modules/service/serviceInvoice/create/PartsRequest';
import ServiceMutation from 'services/graphQL/mutate/service/ServiceMutation';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const ownStyle = makeStyles((theme) => (
    {
        itemCls: {
            padding: '8px',
            margin: '10px',
            border: `solid 1px rgba(${theme.palette.rgb.black}, 0.12)`,
            '&:nth-child(even)': {
                backgroundColor: theme.palette.background.titanWhite,
            },
            '& p, & h6': {
                fontSize: 12,
            },
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.cornFlowerBlueTrans,
            [theme.breakpoints.down('sm')]: {
                '& > div:nth-child(2), & > div:nth-child(3)': {
                    display: 'none',
                },
            },
        },
        labelHeader: {
            padding: '6px',
            fontSize: '14px !important',
        },
        timePickerCls: {
            padding: 0,
            width: '100%',
        },
        labelItem: {
            lineHeight: '30px',
        },
        btnSave: {
            float: 'right',
            marginRight: '10px',
        },
        spaceBelow: {
            marginBottom: '5px',
            '& > p': {
                whiteSpace: 'initial !important',
                lineHeight: 1.5,
            },
        },
        input: {
            marginBottom: '5px',
            height: '35px',
            fontSize: '13px',
        },
        textArea: {
            resize: 'none',
        },
        jobStatus: {
            width: '170px',
            [theme.breakpoints.down('sm')]: {
                width: '150px',
            },
        },
        technician: {
            width: '170px',
            [theme.breakpoints.down('sm')]: {
                width: '150px',
            },
        },
        leftArea: {
            height: '100px',
            [theme.breakpoints.down(1024)]: {
                height: 'auto',
                '& > div': {
                    flexWrap: 'nowrap',
                },
            },
            [theme.breakpoints.down('sm')]: {
                '& > div': {
                    flexWrap: 'wrap',
                },
            },
        },
        rightArea: {
            '& > div': {
                alignSelf: 'center',
            },
        },
        btnParts: {
            '& svg': {
                fill: theme.palette.background.white,
                width: '1em',
                height: '1em',
            },
        },
        progressContainer: {
            width: '200px',
            '& > div': {
                width: '100%',
                height: '15px',
                border: `0.5px solid ${theme.palette.border.mercuryLight}`,
                backgroundColor: theme.palette.background.white,
            },
        },
    }
));

const TechnicianJobForm = ({
    item,
    reconCategories,
    technicians,
    loadingUsers,
}) => {
    const keyStore = new KeyStore();
    const OPERATION_CODE_EDIT = keyStore.hasPermission(Permission.SERVICE_OPERATION_CODE_EDIT);

    const {
        invoiceNumber,
        jobNumber,
        technicianId,
        serviceJobId,
        hours,
        repairs,
        serviceInvoice,
        status,
        customerStates,
        cause,
        correction,
        recommend1,
        recommend2,
        recommend3,
        recommend4,
        recommend5,
        technician,
    } = item;

    const recomendations = `${recommend1} ${recommend2} ${recommend3} ${recommend4} ${recommend5}`.trim();
    const {
        status: invoiceStatus,
        vehicleStock,
        vehicleMake,
        vehicleModel,
        vehicleTrim,
        vehicleYear,
        vehicleVin,
    } = serviceInvoice;

    const stockNumber = vehicleStock > 0 ? vehicleStock : 'No stock available';
    const classes = { ...ownStyle(), ...buttonStyles() };

    const techniciansCategories = reconCategories.filter((rc) => rc.forTechnicians);
    const [isPartsRequestOpen, setIsPartsRequestOpen] = useState(false);
    const [record, setRecord] = useState({
        cause,
        correction,
        recomendations,
        serviceJobId,
        status,
        technician: technicianId,
        technicianFullName: technician,
    });

    const [saveData, { loading: saving }] = useMutation(ServiceMutation.UPDATE_SERVICE_TECHNICIAN_JOB, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.updateTechnicianJob) {
                ModalUtils.successMessage(null, 'saved successfully');

                const selectedTechnician = technicians.find((t) => t.userId === record.technician);
                if (selectedTechnician) {
                    setRecord((prev) => ({
                        ...prev,
                        technicianFullName: `${selectedTechnician.firstName} ${selectedTechnician.lastName}`,
                    }));
                }
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const onChange = (name, value) => {
        setRecord((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onSave = () => {
        const {
            technicianFullName,
            ...others
        } = record;

        saveData({
            variables: others,
        });
    };

    const { technicianFullName } = record;
    const technicianLabel = !StringUtils.isEmpty(technicianFullName) ? StringUtils.toPascalCase(technicianFullName.toLowerCase()) : 'Not assigned';
    return (
        <>
            <Paper elevation={3} className={classes.itemCls}>
                <Grid container spacing={1}>
                    <Grid item container xs={12}>
                        <Grid className={classes.header} item xs={12} md={12}>
                            <div>
                                <Typography className={classes.labelHeader} variant="h6" noWrap>{`RO: ${invoiceNumber} - Job #: ${jobNumber}`}</Typography>
                            </div>
                            <div>
                                <Typography className={classes.labelHeader} variant="h6" noWrap>{`RO Status: ${invoiceStatus}`}</Typography>
                            </div>
                            <div>
                                <Typography className={classes.labelHeader} variant="h6" noWrap>
                                    {`Technician: ${technicianLabel}`}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className={classes.leftArea} item container md={6} xs={12}>
                        <Grid item container md={7} xs={12}>
                            <Grid item xs={12} md={4} className={classes.spaceBelow}>
                                <Typography color="primary" variant="h6" noWrap>Vehicle:</Typography>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.spaceBelow}>
                                <Typography noWrap>{`${stockNumber} - ${vehicleYear} ${vehicleMake} ${vehicleModel} ${vehicleTrim}`}</Typography>
                                <Typography noWrap>{vehicleVin}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.spaceBelow}>
                                <Typography color="primary" variant="h6" noWrap>Customer States:</Typography>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.spaceBelow}>
                                <Typography noWrap>{customerStates}</Typography>
                            </Grid>
                            {repairs?.length > 0 && (
                                <>
                                    <Grid item xs={12} md={4} className={classes.spaceBelow}>
                                        <Typography color="primary" variant="h6" noWrap>Parts Status:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.spaceBelow}>
                                        <Tooltip
                                            title={`${repairs.filter((r) => r.partStatus === PartStatus.PART_RECEIVED).length}/${repairs.length}`}
                                            placement="top"
                                        >
                                            <div className={classes.progressContainer}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={
                                                        (repairs.filter((r) => r.partStatus === PartStatus.PART_RECEIVED).length * 100) / repairs.length
                                                    }
                                                />
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid item container md={5} xs={12}>
                            <Grid item xs={12} md={4}>
                                <Typography className={classes.labelItem} color="primary" variant="h6" noWrap>Job Status:</Typography>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.spaceBelow}>
                                <DropdownQuery
                                    className={classes.jobStatus}
                                    name="status"
                                    allowEmptyLine={false}
                                    value={record.status}
                                    placeHolder="select the status"
                                    onChange={onChange}
                                    dataSource={{
                                        localData: SERVICE_JOBS_STATUS_LIST,
                                        idField: 'value',
                                        descriptionField: 'label',
                                    }}
                                />
                            </Grid>
                            {OPERATION_CODE_EDIT && (
                                <>
                                    <Grid item xs={12} md={4} className={classes.spaceBelow}>
                                        <Typography className={classes.labelItem} color="primary" variant="h6" noWrap>Technician:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.spaceBelow}>
                                        <Select
                                            className={classes.technician}
                                            name="technician"
                                            onChange={(name, newValue) => onChange(name, newValue)}
                                            value={record.technician}
                                            options={technicians.map((user) => ({
                                                value: user.userId,
                                                label: `${user.firstName} ${user.lastName}`,
                                            }))}
                                            nowrap
                                            size="sm"
                                            loading={loadingUsers}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={4} className={classes.spaceBelow}>
                                <Typography color="primary" variant="h6" noWrap>Hours:</Typography>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.spaceBelow}>
                                <Typography noWrap>{hours}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.rightArea} item container md={6} xs={12}>
                        <Grid item xs={12} md={4}>
                            <Typography color="primary" variant="h6" noWrap>Cause:</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Form.Control
                                className={classes.input}
                                type="text"
                                name="cause"
                                value={record.cause ?? ''}
                                onChange={({ target: { name, value } }) => onChange(name, value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography color="primary" variant="h6" noWrap>Correction:</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Form.Control
                                className={classes.input}
                                type="text"
                                name="correction"
                                value={record.correction ?? ''}
                                onChange={({ target: { name, value } }) => onChange(name, value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography color="primary" variant="h6" noWrap>Recommends:</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Form.Control
                                className={classes.input}
                                type="text"
                                name="recomendations"
                                value={record.recomendations ?? ''}
                                onChange={({ target: { name, value } }) => onChange(name, value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            startIcon={<PartOutlined />}
                            size="small"
                            className={clsx(classes.containedGreen, classes.btnParts)}
                            disabled={saving || techniciansCategories.length === 0}
                            onClick={() => setIsPartsRequestOpen(true)}
                        >
                            Parts Request
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<SaveOutlinedIcon />}
                            size="small"
                            color="secondary"
                            className={classes.btnSave}
                            disabled={saving}
                            onClick={onSave}
                        >
                            {saving ? 'Saving...' : 'Save'}
                            {saving && <CircularProgress size={20} />}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            {isPartsRequestOpen && (
                <PartsRequest
                    job={item}
                    techniciansCategories={techniciansCategories}
                    closeForm={() => setIsPartsRequestOpen(false)}
                />
            )}
        </>
    );
};

TechnicianJobForm.propTypes = {
    item: PropTypes.shape({
        serviceJobId: PropTypes.string.isRequired,
        invoiceNumber: PropTypes.number.isRequired,
        jobNumber: PropTypes.number.isRequired,
        technicianId: PropTypes.number,
        status: PropTypes.string,
        lotName: PropTypes.string,
        technician: PropTypes.string,
        hours: PropTypes.number,
        customerStates: PropTypes.string,
        cause: PropTypes.string,
        correction: PropTypes.string,
        recommend1: PropTypes.string,
        recommend2: PropTypes.string,
        recommend3: PropTypes.string,
        recommend4: PropTypes.string,
        recommend5: PropTypes.string,
        repairs: PropTypes.array,
        serviceInvoice: PropTypes.shape({
            advisor: PropTypes.string,
            status: PropTypes.string,
            vehicleStock: PropTypes.number,
            vehicleYear: PropTypes.string,
            vehicleMake: PropTypes.string,
            vehicleModel: PropTypes.string,
            vehicleTrim: PropTypes.string,
            vehicleVin: PropTypes.string,
            customer: PropTypes.oneOfType([PropTypes.object]),
        }).isRequired,
    }).isRequired,
    reconCategories: PropTypes.array.isRequired,
    technicians: PropTypes.array.isRequired,
    loadingUsers: PropTypes.bool.isRequired,
};

export default TechnicianJobForm;
