import React, { useEffect, useState } from 'react';
import {
    makeStyles, useTheme,
    Button, Tooltip,
    Dialog, DialogContent,
    useMediaQuery,
} from '@material-ui/core';
import {
    ENTITY_TYPE,
    ENTITY_FIELD_NAME,
    CHART_COLUMN_DATA_TYPE,
} from 'utils/enum/BusinessIntelligenceEnum';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ArrayUtils from 'lib/ArrayUtils';
import TableUtils from 'utils/TableUtils';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import GeneralUtils from 'utils/GeneralUtils';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import Chart from 'components/widgets/businessIntelligence/Chart';
import AddEntityDialog from 'components/modules/settings/businessIntelligence/AddEntityDialog';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import Search from 'components/widgets/Search';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import BIQuery from 'services/graphQL/query/businessIntelligence/Query';
import BIMutation from 'services/graphQL/mutate/businessIntelligence/Mutation';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import SettingsStyle from 'styles/modules/businessIntelligence/SettingsStyle';

// Icons
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useBIStyles = makeStyles((theme) => SettingsStyle.content(theme));
const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '96%',
        paddingLeft: theme.spacing(0.4),
        paddingRight: theme.spacing(0.4),
        [theme.breakpoints.down('sm')]: {
            marginBottom: '50px',
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            flexDirection: 'row',
            borderBottom: `1px solid ${theme.palette.border.ghost}`,
            paddingBottom: '15px',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            '& > div:nth-child(2)': {
                width: '70%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    justifyContent: 'flex-start',
                },
                '& > div:nth-child(1)': {
                    width: '300px',
                    marginRight: '10px',
                },
                '& > div:nth-child(2)': {
                    width: '350px',
                    marginRight: '10px',
                },
                '& > span:nth-child(3) > button': {
                    marginRight: '5px',
                },
            },
            '& > div:nth-child(1)': {
                width: '30%',
                justifyContent: 'flex-start',
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    marginBottom: '5px',
                },
                '& > span > button': {
                    height: '32px',
                },
            },
        },
    },
    input: {
        fontSize: '13px',
        '& > div:nth-child(1)': {
            fontSize: '13px !important',
        },
    },
    '@global': {
        '.css-26l3qy-menu': {
            minWidth: '150px',
        },
        '.css-26l3qy-menu div, .css-2b097c-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
        '.css-26l3qy-menu > div, .css-2b097c-menu > div': {
            maxHeight: '130px',
            overflowX: 'hidden',
        },
    },
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '20px 10px',
        width: '100%',
        minHeight: '300px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    listWrapper: {
        height: '91%',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: '15px',
        lineHeight: 2,
        [theme.breakpoints.down('sm')]: {
            height: '500px',
        },
    },
    listItem: {
        marginBottom: '5px',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        paddingLeft: '5px',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: theme.palette.background.silver,
            color: theme.palette.text.darkShark,
        },
        '& > span': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'inline-block',
            width: '85%',
            height: '19px',
        },
        '& > div > span > button': {
            minWidth: '25px',
            marginRight: '5px',
            '& span.MuiButton-startIcon': {
                margin: 0,
            },
        },
    },
    globalSearch: {
        width: '250px',
        marginRight: '5px',
        [theme.breakpoints.down('sm')]: {
            width: '180px',
        },
    },
}));

const GlobalRepoSettings = () => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const biClasses = { ...useBIStyles(), ...buttonStyles() };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [state, setState] = useState({
        companies: [],
        selectedCompany: null,
        resources: [],
        selectedResource: null,
        selectedResourceTotal: null,
        selectedResourceRecords: null,
        selectedResourceDefaultDateValues: null,
        selectedResourceDefaultCustomFilterValues: null,
        isResourcePreviewOpen: false,
        chartKey: null,
        innerChartKey: null,
        chartWidth: null,
        globalCharts: [],
        globalSearch: null,
        isEntityDialogOpen: false,
        selectedChartId: null,
        selectedChartLabel: null,
        selectedChartQuery: null,
        selectedChartType: null,
        selectedChartOptions: null,
        selectedChartLoadedColumns: [],
        selectedChartExtraOptions: null,
        isCustomReport: false,
        isDeletePromptVisible: false,
    });

    const {
        innerChartKey,
        chartKey,
        chartWidth,
        companies,
        selectedCompany,
        resources,
        selectedResource,
        isResourcePreviewOpen,
        selectedResourceTotal,
        selectedResourceRecords,
        selectedResourceDefaultDateValues,
        selectedResourceDefaultCustomFilterValues,
        globalCharts,
        globalSearch,
        isEntityDialogOpen,
        selectedChartId,
        selectedChartLabel,
        selectedChartQuery,
        selectedChartType,
        selectedChartOptions,
        selectedChartLoadedColumns,
        selectedChartExtraOptions,
        isCustomReport,
        isDeletePromptVisible,
    } = state;

    const {
        data: companiesData,
        loading: loadingCompanies,
        error: errorLoadingCompanies,
    } = useQuery(AIQuery.PULL_ACTIVE_COMPANIES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: chartsData,
        loading: loadingCharts,
        error: errorLoadingCharts,
        refetch: refetchCharts,
    } = useQuery(BIQuery.PULL_CHARTS_GLOBAL_REPOSITORY, {
        variables: {
            search: globalSearch,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    const {
        data: resourcesData,
        loading: loadingResources,
        error: errorLoadingResources,
    } = useQuery(BIQuery.PULL_BI_RESOURCES_COMPANY, {
        variables: {
            companyCode: selectedCompany,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
        skip: selectedCompany == null,
    });

    const [pullQueryData, { loading: pullingQueryData }] = useLazyQuery(BIQuery.PULL_QUERY_RESULT_COMPANY, {
        onCompleted: (response) => {
            const result = response.pullQueryResultInCompany;
            if (result) {
                const { output: { records, total }, defaultDateValues, defaultCustomFilterValues } = result;

                setState((prevState) => ({
                    ...prevState,
                    selectedResourceTotal: total,
                    selectedResourceRecords: records,
                    selectedResourceDefaultDateValues: defaultDateValues,
                    selectedResourceDefaultCustomFilterValues: defaultCustomFilterValues,
                    isResourcePreviewOpen: true,
                    chartKey: TableUtils.generateUUID(),
                }));
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getDataPreview, { loading: loadingColumns }] = useLazyQuery(BIQuery.PREVIEW_QUERY_RESULTS, {
        onCompleted: (response) => {
            if (response) {
                const data = response.previewQueryResults;
                if (data) {
                    const {
                        output: { records, total },
                        defaultDateValues,
                    } = data;

                    setState((prevState) => ({
                        ...prevState,
                        selectedChartLoadedColumns: records,
                        selectedChartExtraOptions: {
                            total,
                            defaultDateValues,
                        },
                    }));
                }
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
            setState((prevState) => ({
                ...prevState,
                selectedChartOptions: null,
            }));
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const executeQuery = (payload, filter = {}) => {
        const input = payload;
        const { filters } = selectedChartOptions ?? {};

        if (ArrayUtils.isNotEmpty(filters)) {
            input.variables.filters = filters
                .filter((f) => f.dataType === CHART_COLUMN_DATA_TYPE.DATE)
                .map(({
                    column,
                    dataType,
                    startDate,
                    endDate,
                }) => {
                    const { column: columnName, currentStartDate, currentEndDate } = filter;

                    return {
                        column,
                        dataType,
                        startDateVariable: startDate,
                        endDateVariable: endDate,
                        currentStartDate: columnName === column && currentStartDate ? currentStartDate : null,
                        currentEndDate: columnName === column && currentEndDate ? currentEndDate : null,
                    };
                });
        }

        getDataPreview(input);
    };

    const [addChart, { loading: addingChart }] = useMutation(BIMutation.ADD_CHART_GLOBAL_REPOSITORY, {
        onCompleted: (response) => {
            if (response) {
                refetchCharts();
                ModalUtils.successMessage(null, 'Chart added successfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [savingChart, { loading: savingGlobalChart }] = useMutation(BIMutation.SAVE_GLOBAL_CHART, {
        onCompleted: (response) => {
            if (response) {
                refetchCharts();
                ModalUtils.successMessage(null, 'Chart saved successfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (errorLoadingResources) {
            ModalUtils.errorMessage(errorLoadingResources?.graphQLErrors);
            return;
        }

        if (!loadingResources) {
            const data = resourcesData?.pullBIResourcesPerCompany;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    resources: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingResources, errorLoadingResources]);

    useEffect(() => {
        if (errorLoadingCompanies) {
            ModalUtils.errorMessage(errorLoadingCompanies?.graphQLErrors);
            return;
        }

        if (!loadingCompanies) {
            const data = companiesData?.pullActiveCompanies;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    companies: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCompanies, errorLoadingCompanies]);

    useEffect(() => {
        if (errorLoadingCharts) {
            ModalUtils.errorMessage(errorLoadingCharts?.graphQLErrors);
            return;
        }

        if (!loadingCharts) {
            const data = chartsData?.pullChartsInGlobalRepository;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    isEntityDialogOpen: false,
                    isDeletePromptVisible: false,
                    globalCharts: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCharts, errorLoadingCharts]);

    useEffect(() => {
        async function getChartWidth() {
            if (isResourcePreviewOpen) {
                await GeneralUtils.sleep(1);
                const width = document.getElementById('preview-wrapper')?.clientWidth - 80;

                setState((prevState) => ({
                    ...prevState,
                    chartWidth: width,
                }));
            }
        }

        getChartWidth();
    }, [isResourcePreviewOpen]);

    const onChange = (name, value) => {
        const changingCompany = name === 'selectedCompany';
        const isEntityType = name === ENTITY_FIELD_NAME.SELECTED_ENTITY_TYPE;

        setState((prevState) => ({
            ...prevState,
            [name.replace('Entity', 'Chart').replace('chartKey', 'innerChartKey')]: value,
            ...(changingCompany ? { selectedResource: null } : {}),
            ...(isEntityType && !StringUtils.isEmpty(selectedChartType) && value !== selectedChartType ? {
                selectedChartOptions: null,
                innerChartKey: null,
            } : {}),
        }));
    };

    const togglePreviewResource = () => {
        if (!isResourcePreviewOpen) {
            pullQueryData({
                variables: {
                    companyCode: selectedCompany,
                    id: selectedResource,
                    type: ENTITY_TYPE.CHART,
                },
            });
            return;
        }

        setState((prevState) => ({
            ...prevState,
            chartWidth: null,
            isResourcePreviewOpen: false,
        }));
    };

    const addChartToGlobal = () => {
        addChart({
            variables: {
                companyCode: selectedCompany,
                id: selectedResource,
            },
        });
    };

    const toggleCreationDialog = (id) => {
        if (id && !isEntityDialogOpen) {
            const chart = globalCharts.find((gc) => gc.id === id);
            if (chart) {
                executeQuery({
                    variables: {
                        statement: chart.query,
                    },
                });

                setState((prevState) => ({
                    ...prevState,
                    selectedChartId: id,
                    selectedChartLabel: chart.label,
                    selectedChartQuery: chart.query,
                    selectedChartType: chart.type,
                    selectedChartOptions: JSON.parse(chart.options),
                    selectedChartLoadedColumns: [],
                    selectedChartExtraOptions: null,
                    innerChartKey: TableUtils.generateUUID(),
                    isEntityDialogOpen: true,
                }));
            }

            return;
        }

        setState((prevState) => ({
            ...prevState,
            selectedChartId: null,
            selectedChartLabel: null,
            selectedChartQuery: null,
            selectedChartType: null,
            selectedChartOptions: null,
            selectedChartLoadedColumns: [],
            selectedChartExtraOptions: null,
            innerChartKey: null,
            isEntityDialogOpen: !isEntityDialogOpen,
        }));
    };

    const toggleDeletePrompt = (id) => {
        setState((prevState) => ({
            ...prevState,
            selectedChartId: id,
            isDeletePromptVisible: !isDeletePromptVisible,
        }));
    };

    const onDeleteAction = () => {
        const chart = globalCharts.find((gc) => gc.id === selectedChartId);
        if (chart) {
            savingChart({
                variables: {
                    id: selectedChartId,
                    label: chart.label,
                    query: chart.query,
                    type: chart.type,
                    options: chart.options,
                    active: false,
                },
            });
        }
    };

    const onSaveChart = (isDelete = false) => {
        savingChart({
            variables: {
                id: selectedChartId,
                label: selectedChartLabel,
                query: selectedChartQuery,
                type: selectedChartType,
                options: JSON.stringify(selectedChartOptions),
                active: !isDelete,
            },
        });
    };

    const onGlobalResourceSearch = (input) => {
        setState((prevState) => ({
            ...prevState,
            globalSearch: input,
            globalCharts: [],
        }));
    };

    const selectedChart = resources.find((r) => r.id === selectedResource) ?? {};
    const {
        label,
        query,
        type,
        options,
    } = selectedChart;
    const chartInPreviewOptions = JSON.parse(options ?? '{}');
    return (
        <>
            <div className={classes.container}>
                <div>
                    <div>
                        <Search className={classes.globalSearch} fullWidth onKeyDown={(input) => onGlobalResourceSearch(input)} />
                        <Tooltip title="Create new resource" placement="top">
                            <span>
                                <Button
                                    size="small"
                                    disabled={pullingQueryData || addingChart}
                                    className={classes.containedSecondaryInfo}
                                    onClick={() => toggleCreationDialog()}
                                >
                                    Create Resource
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                    <div>
                        <Select
                            nowrap
                            size="sm"
                            placeholder="Company"
                            loading={loadingCompanies}
                            disabled={addingChart}
                            name="selectedCompany"
                            className={classes.input}
                            onChange={(name, value) => onChange(name, value)}
                            value={selectedCompany}
                            options={companies.map((c) => ({
                                value: c.companyCode,
                                label: `${c.companyCode} - ${c.companyName}`,
                            }))}
                        />
                        <Select
                            nowrap
                            size="sm"
                            placeholder="Resource"
                            loading={loadingResources}
                            disabled={selectedCompany == null || addingChart}
                            name="selectedResource"
                            className={classes.input}
                            onChange={(name, value) => onChange(name, value)}
                            value={selectedResource}
                            options={resources.map((c) => ({
                                value: c.id,
                                label: c.label,
                            }))}
                        />
                        <Tooltip title="Add existing resource" placement="top">
                            <span>
                                <Button
                                    size="small"
                                    disabled={selectedResource == null || pullingQueryData || addingChart}
                                    className={classes.containedGreen}
                                    onClick={addChartToGlobal}
                                >
                                    Add
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="Preview existing resource" placement="top">
                            <span>
                                <Button
                                    size="small"
                                    disabled={selectedResource == null || pullingQueryData || addingChart}
                                    className={classes.containedSecondaryInfo}
                                    onClick={togglePreviewResource}
                                >
                                    Preview
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                </div>
                <div className={classes.listWrapper}>
                    {globalCharts.map(({
                        id,
                        label: chartLabel,
                        type: chartType,
                    }, index) => (
                        <div className={classes.listItem} key={index}>
                            <div>{`${index + 1}. ${chartLabel} | ${chartType}`}</div>
                            <div>
                                <Tooltip title="Edit">
                                    <span>
                                        <Button
                                            size="small"
                                            disabled={pullingQueryData || addingChart}
                                            className={classes.containedSecondaryInfo}
                                            startIcon={<EditOutlinedIcon />}
                                            onClick={() => toggleCreationDialog(id)}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <span>
                                        <Button
                                            size="small"
                                            disabled={pullingQueryData || addingChart}
                                            className={classes.containedError}
                                            startIcon={<DeleteOutlineOutlinedIcon />}
                                            onClick={() => toggleDeletePrompt(id)}
                                        />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {isResourcePreviewOpen && (
                <Dialog
                    open
                    fullWidth
                    maxWidth="lg"
                    disableBackdropClick
                    disableEscapeKeyDown
                    scroll="paper"
                    onMouseDown={(e) => e.stopPropagation()}
                    disableEnforceFocus
                >
                    <DialogAppBar
                        appBarClassName={classes.AppBar}
                        title="Preview"
                        onClose={() => togglePreviewResource()}
                        toolbarSize="md"
                    />
                    <DialogContent id="preview-wrapper">
                        <div className={classes.content}>
                            {chartWidth > 0 && (
                                <Chart
                                    cache
                                    key={chartKey}
                                    tableWidth={chartWidth}
                                    height={500}
                                    label={label}
                                    type={type}
                                    query={query}
                                    totalRecords={selectedResourceTotal}
                                    data={selectedResourceRecords}
                                    options={chartInPreviewOptions}
                                    pullQueryResultsWithFilters={
                                        (filter, currentFiltersInChart) => pullQueryData({
                                            variables: {
                                                companyCode: selectedCompany,
                                                id: selectedResource,
                                                type: ENTITY_TYPE.CHART,
                                                filters: BIHelper.buildFiltersData(chartInPreviewOptions?.filters, filter, currentFiltersInChart),
                                            },
                                        })
                                    }
                                    defaultDateValues={selectedResourceDefaultDateValues}
                                    defaultCustomFilterValues={selectedResourceDefaultCustomFilterValues}
                                    isGlobalReport
                                />
                            )}
                        </div>
                    </DialogContent>
                </Dialog>
            )}
            <AddEntityDialog
                classes={biClasses}
                isMobile={isMobile}
                canWrite={false}
                chartKey={innerChartKey}
                isRuleEntity={false}
                isDialogOpen={isEntityDialogOpen}
                toggleDialog={() => toggleCreationDialog()}
                onChange={({ target: { name, value } }) => onChange(name, value)}
                onSaveEntity={onSaveChart}
                savingEntity={savingGlobalChart}
                getDataPreview={executeQuery}
                loadingColumns={loadingColumns}
                selectedEntityLabel={selectedChartLabel}
                selectedEntityQuery={selectedChartQuery}
                selectedEntityType={selectedChartType}
                selectedEntityOptions={selectedChartOptions}
                selectedEntityLoadedColumns={selectedChartLoadedColumns}
                selectedChartExtraOptions={selectedChartExtraOptions}
                isCustomReport={isCustomReport}
                isGlobalReport
            />
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this global resource?"
                open={isDeletePromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={onDeleteAction}
                disablePrimaryButton={savingGlobalChart}
                disableSecondaryButton={savingGlobalChart}
            />
        </>
    );
};

export default GlobalRepoSettings;
