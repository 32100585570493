/* eslint-disable import/prefer-default-export */
const ServiceInvoiceStatus = Object.freeze({
    WRITE_UP: 'Write Up',
    APPROVAL_REQUEST: 'Approval Request',
    IN_PROGRESS: 'In-Progress',
    CLOSED: 'Closed',
    COMPLETED: 'Completed',
    ESTIMATE: 'Estimate',
    VOID: 'Void',
    NEW: 'New',
});

const ServiceSubModules = Object.freeze({
    REPAIRS_ORDERS: 'repairs-orders',
    CUSTOMERS: 'customers',
    TECHNICIAN_JOB_LIST: 'technician-jobs',
    OPERATION_CODES: 'operation-codes',
});

const ServiceActions = Object.freeze({
    PRINT_INVOICE: 'Print Invoice',
    PRINT_CONSENT: 'Print Consent',
    CHANGE_STATUS: 'Change-Status',
    PRINT_INSPECTION_LIST: 'Print Inspection List',
});

const ServiceJobStatus = Object.freeze({
    DIAGNOSIS: 'Diagnosis',
    PARTS_PRICE_REQUEST: 'Parts Price Request',
    WAITING_FOR_CUSTOMER_APPROVAL: 'Waiting for Customer Approval',
    IN_PROGRESS: 'In-Progress',
    COMPLETED: 'Completed',
});

const PAY_TYPE = {
    WARRANTY_PARTS_LABOR: 'WPL',
    WARRANTY_LABOR_ONLY: 'WLO',
    WARRANTY_PARTS_ONLY: 'WPO',
    WARRANTY_PAY_SUBLET: 'WPS',
    INTERNAL: 'I',
    AFTER_SALE_REPAIR: 'ASR',
    WE_OWE: 'WO',
    CUSTOMER_PAY: 'C',
};

const SERVICE_JOBS_STATUS_LIST = [
    { label: ServiceJobStatus.DIAGNOSIS, value: ServiceJobStatus.DIAGNOSIS },
    { label: ServiceJobStatus.PARTS_PRICE_REQUEST, value: ServiceJobStatus.PARTS_PRICE_REQUEST },
    { label: ServiceJobStatus.WAITING_FOR_CUSTOMER_APPROVAL, value: ServiceJobStatus.WAITING_FOR_CUSTOMER_APPROVAL },
    { label: ServiceJobStatus.IN_PROGRESS, value: ServiceJobStatus.IN_PROGRESS },
    { label: ServiceJobStatus.COMPLETED, value: ServiceJobStatus.COMPLETED },
];

const INVOICE_STATUS_LIST = [
    { label: ServiceInvoiceStatus.WRITE_UP, value: ServiceInvoiceStatus.WRITE_UP },
    { label: ServiceInvoiceStatus.ESTIMATE, value: ServiceInvoiceStatus.ESTIMATE },
    { label: ServiceInvoiceStatus.APPROVAL_REQUEST, value: ServiceInvoiceStatus.APPROVAL_REQUEST },
    { label: ServiceInvoiceStatus.IN_PROGRESS, value: ServiceInvoiceStatus.IN_PROGRESS },
    { label: ServiceInvoiceStatus.COMPLETED, value: ServiceInvoiceStatus.COMPLETED },
    { label: ServiceInvoiceStatus.CLOSED, value: ServiceInvoiceStatus.CLOSED },
    { label: ServiceInvoiceStatus.VOID, value: ServiceInvoiceStatus.VOID },
];

const PayTypeData = [
    { value: PAY_TYPE.CUSTOMER_PAY, label: 'C: Customer Pay', withCustomerPay: true },
    { value: PAY_TYPE.WE_OWE, label: 'WO: We Owe', withCustomerPay: true },
    { value: PAY_TYPE.AFTER_SALE_REPAIR, label: 'ASR: After Sale Repair', withCustomerPay: true },
    { value: PAY_TYPE.INTERNAL, label: 'I: Internal Pay', withCustomerPay: false },
    { value: PAY_TYPE.WARRANTY_PARTS_LABOR, label: 'WPL: Warranty Pay - Parts & Labor', withCustomerPay: null },
    { value: PAY_TYPE.WARRANTY_PARTS_ONLY, label: 'WPO: Warranty Pay - Parts Only', withCustomerPay: null },
    { value: PAY_TYPE.WARRANTY_LABOR_ONLY, label: 'WLO: Warranty Pay - Labor Only', withCustomerPay: null },
    { value: PAY_TYPE.WARRANTY_PAY_SUBLET, label: 'WPS: Warranty Pay - Sublet', withCustomerPay: null },
];

export {
    ServiceInvoiceStatus,
    ServiceSubModules,
    ServiceActions,
    ServiceJobStatus,
    INVOICE_STATUS_LIST,
    SERVICE_JOBS_STATUS_LIST,
    PayTypeData,
    PAY_TYPE,
};
