const VendorType = Object.freeze({
    FINANCIAL_INSTITUTION: 'Financial Institution',
    FLOORPLAN: 'Floorplan',
    INSURANCE_COMPANY: 'Insurance Company',
    RECOVERY_AGENT: 'Recovery Agent',
    REPAIR_VENDOR: 'Repair Vendor',
    EXPENSES: 'Expenses',
    PRODUCT_VENDOR: 'Product Vendor',
    APPRAISAL_DISTRICT: 'Appraisal District',
    TAX_ASSESSOR_COLLECTOR: 'Tax Assessor-Collector',
});

const VendorFormula = Object.freeze({
    RESERVE: 'RESERVE',
    DISCOUNT: 'DISCOUNT',
});

export { VendorType, VendorFormula };
