/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, useMediaQuery, Button,
} from '@material-ui/core';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import { useTheme } from '@material-ui/core/styles';
import ArrayUtils from 'lib/ArrayUtils';
import ModalUtils from 'utils/ModalUtils';
import ImagesUtils from 'utils/ImagesUtils';
import ButtonStyles from 'styles/theme/Button';
import CatalogEnum from 'utils/enum/CatalogEnum';
import CatalogQuery from 'services/graphQL/query/CatalogQuery';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';
import ServiceMutation from 'services/graphQL/mutate/service/ServiceMutation';
import useImagesActions from 'components/modules/inventory/hooks/useImagesActions';
import If from 'components/widgets/conditional/If';
import VirtualTable from 'components/widgets/VirtualTable';
import InspectionImagesDialog from 'components/modules/inventory/read/InspectionImagesDialog';
import InspectionCategoryPanel from 'components/modules/inventory/read/panels/InspectionCategoryPanel';
import StatusCheck from 'components/modules/service/serviceInvoice/read/StatusCheck';
import CommentsField from 'components/modules/service/serviceInvoice/read/CommentsField';
import RepairSwitch from 'components/modules/service/serviceInvoice/read/RepairSwitch';
import JobTypesSelect from 'components/modules/service/serviceInvoice/read/JobTypesSelect';
import StringUtils from 'lib/StringUtils';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: '20px',
            minWidth: 'initial',
        },
    },
    tableContainer: {
        paddingLeft: '15px',
        paddingRight: '15px',
        height: '100%',
        width: '100%',
        minWidth: '900px',
        overflow: 'hidden',
        '& .ReactVirtualized__Table__headerRow': {
            display: 'none',
        },
        '& .ReactVirtualized__Grid': {
            paddingBottom: '100px',
        },
        '& .ReactVirtualized__Table__rowColumn': {
            padding: '7px 0px',
            fontSize: '13px',
            color: theme.palette.text.outerSpace,
            justifyContent: 'left',
            display: 'flex',
            '& > span': {
                fontSize: '13px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
    },
    categoryText: {
        color: theme.palette.text.white,
        fontWeight: 'bold',
    },
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
    uploadButton: {
        fontSize: '12px',
        borderRadius: '0px',
    },
    greenUploadButton: {
        backgroundColor: `${theme.palette.background.green} !important`,
    },
    passCheck: {
        color: theme.palette.text.green,
    },
    soonCheck: {
        color: theme.palette.text.barberry,
    },
    failCheck: {
        color: theme.palette.text.red,
    },
    select: {
        width: '100%',
        border: `1px solid ${theme.palette.border.ghost}`,
        borderRadius: '0.25rem',
        height: '32px',
        '& > div': {
            paddingLeft: '10px !important',
            paddingRight: '10px !important',
        },
    },
    invalidSelect: {
        border: `1px solid ${theme.palette.border.red}`,
    },
    actions: {
        position: 'absolute',
        right: '15px',
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.border.ghost}`,
        bottom: '10px',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '10px',
        '& > button': {
            minWidth: '80px',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            left: '15px',
            right: 'initial',
        },
    },
}));

const MultiPointInspection = ({
    invoiceNumber,
    stockNumber,
    inspectionComplete,
    updateInspectionItems,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = { ...useStyles(), ...buttonStyles() };
    const {
        moveType, setPhotoIndex, uploadImage,
    } = useImagesActions();
    const [state, setState] = useState({
        categories: [],
        jobTypes: [],
        isPicturesFormOpen: false,
        isUploadingImages: false,
        openImagePreview: false,
        selectedItem: null,
        photoIndex: -1,
        savedItemsLoaded: false,
    });

    const {
        data: inspectionListItemsData,
        loading: loadingInspectionListItems,
        error: errorInspectionListItems,
    } = useQuery(ServiceQuery.GET_INSPECTION_ITEMS, {
        variables: {
            activeItems: true,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: savedItemsData,
        loading: loadingSavedItems,
        error: errorSavedItems,
    } = useQuery(ServiceQuery.GET_SAVED_INSPECTION_ITEMS, {
        variables: {
            invoiceNumber,
        },
        skip: state.categories.length === 0 || invoiceNumber <= 0,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: jobTypesData,
        loading: loadingJobTypes,
        error: errorJobTypes,
    } = useQuery(CatalogQuery.ENUM_VALUES, {
        variables: {
            descriptions: [CatalogEnum.JOB_TYPE],
            onlyActive: true,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const formatJobTypes = (data) => {
        const clone = cloneDeep(data);

        return clone.map((c) => {
            const { items } = c;
            items.forEach((item) => {
                item.current = { jobTypes: item.jobTypeIds?.split(',')?.map((id) => Number(id)) || [] };
                delete item.jobTypeIds;
            });

            return c;
        });
    };

    useEffect(() => {
        if (errorInspectionListItems) {
            ModalUtils.errorMessage(errorInspectionListItems?.graphQLErrors);
        }

        if (!loadingInspectionListItems) {
            const data = (inspectionListItemsData?.getInspectionItems ?? [])
                .filter((item) => !item.forTechnicians);

            setState((prevState) => ({
                ...prevState,
                categories: formatJobTypes(data),
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingInspectionListItems, errorInspectionListItems]);

    useEffect(() => {
        if (errorJobTypes) {
            ModalUtils.errorMessage(errorJobTypes?.graphQLErrors);
        }

        if (!loadingJobTypes) {
            const data = jobTypesData?.getEnumValues;
            setState((prevState) => ({
                ...prevState,
                jobTypes: data,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingJobTypes, errorJobTypes]);

    useEffect(() => {
        if (errorSavedItems) {
            ModalUtils.errorMessage(errorSavedItems?.graphQLErrors);
        }

        if (!loadingSavedItems) {
            const savedItems = savedItemsData?.getSavedInspectionItems;
            if (Array.isArray(savedItems)) {
                setState((prevState) => {
                    const clone = cloneDeep(prevState.categories);
                    clone.forEach((c) => {
                        const { items } = c;
                        savedItems.forEach((s) => {
                            const item = items.find((i) => i.reconInspectionItemId === s.reconInspectionItemId);
                            if (item) {
                                item.current = {
                                    pass: s.pass,
                                    soon: s.soon,
                                    fail: s.fail,
                                    repair: s.repair,
                                    jobTypes: !StringUtils.isEmpty(s.jobTypeIds) ? s.jobTypeIds.split(',').map((jt) => Number(jt)) : [],
                                    comment: s.comment,
                                    pictures: (s.images || []).map((p) => ({ imageURL: p.imageURL })),
                                };
                            }
                        });
                    });

                    return {
                        ...prevState,
                        savedItemsLoaded: savedItems.length > 0,
                        categories: clone,
                    };
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSavedItems, errorSavedItems]);

    const formatForInspectionSheet = (cats = []) => {
        let output = [];
        cats.forEach((cat) => {
            if (ArrayUtils.isNotEmpty(cat.items)) {
                output.push({
                    reconInspectionCategoryId: cat.reconInspectionCategoryId,
                    name: cat.name,
                    order: cat.order,
                    active: cat.active,
                });
                output = [...output, ...cat.items];
            }
        });

        return output;
    };

    const formattedData = formatForInspectionSheet(state.categories);
    const rowStyleFormat = (row) => {
        const { index } = row;
        if (index < 0) return;

        const record = formattedData[index];
        const isCategory = 'reconInspectionCategoryId' in record;
        if (!isCategory) return;

        // eslint-disable-next-line consistent-return
        return {
            pointerEvents: 'none',
            backgroundColor: theme.palette.background.darkPurple,
        };
    };

    const onChange = (name, value, itemId) => {
        setState((prevState) => {
            try {
                const clone = cloneDeep(prevState.categories);
                for (let x = 0; x < clone.length; x += 1) {
                    const cat = clone[x];
                    const { items } = cat;

                    const index = items.findIndex((item) => item.reconInspectionItemId === itemId);
                    if (index >= 0) {
                        let others = {};
                        switch (`${name}-${value}`) {
                        case 'pass-true':
                            others = {
                                soon: false,
                                fail: false,
                                part: false,
                                repair: false,
                            };
                            break;
                        case 'soon-true':
                            others = {
                                pass: false,
                                fail: false,
                                part: false,
                                repair: false,
                            };
                            break;
                        case 'fail-true':
                            others = {
                                pass: false,
                                soon: false,
                            };
                            break;
                        case 'fail-false':
                            others = {
                                pass: false,
                                soon: false,
                                part: false,
                                repair: false,
                            };
                            break;
                        default:
                            break;
                        }

                        items[index].current = {
                            ...(items[index].current || {}),
                            [name]: value,
                            ...others,
                        };
                        break;
                    }
                }

                return {
                    ...prevState,
                    categories: clone,
                };
            } catch (error) {
                ModalUtils.errorMessage(null, error);
                return prevState;
            }
        });
    };

    const togglePicturesForm = async (item) => {
        const clone = cloneDeep(state.categories);
        const { selectedItem } = state;
        if (!item) {
            const { reconInspectionItemId, current } = selectedItem;
            const cat = clone.find((el) => el.items.some((it) => it.reconInspectionItemId === reconInspectionItemId));
            const element = cat?.items?.find((it) => it.reconInspectionItemId === reconInspectionItemId);

            if (element && current?.pictures) {
                element.current = {
                    ...(element.current || {}),
                    pictures: [...current.pictures],
                };
            }
        }

        setState((prevState) => ({
            ...prevState,
            isPicturesFormOpen: !state.isPicturesFormOpen,
            selectedItem: item,
            categories: clone,
        }));
    };

    const toggleImagePreview = (index) => {
        setState((prevState) => ({
            ...prevState,
            openImagePreview: !state.openImagePreview,
            photoIndex: index,
        }));
    };

    const selectedItemImages = state.selectedItem?.current?.pictures || [];
    const onSetPhotoIndex = (type) => setPhotoIndex(
        type,
        state.photoIndex,
        selectedItemImages,
        setState,
    );

    const onAddImages = async (files) => {
        const maxAllowedFileSize = ImagesUtils.MAX_SIZES_WITH_ERROR.IMAGES.SIZE;
        const anyNotAllowedFile = files.some((file) => file.size > maxAllowedFileSize);
        if (anyNotAllowedFile) {
            ModalUtils.errorMessage(null, ImagesUtils.MAX_SIZES_WITH_ERROR.IMAGES.ERROR);
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isUploadingImages: true,
        }));

        const uploadedImages = await Promise.all(files.map(async (file) => {
            const uploaded = await uploadImage(
                file,
                null,
                null,
                stockNumber,
                ServiceMutation.UPLOAD_RECON_ITEM_IMAGE,
                'uploadReconItemImage',
                false,
            );

            if (uploaded) return uploaded.url;
            return null;
        }));

        const clone = cloneDeep(state.selectedItem);
        const pictures = uploadedImages.filter((e) => e !== null).map((e) => ({ imageURL: e }));

        clone.current = { ...(clone.current || {}), pictures: [...(clone.current?.pictures || []), ...pictures] };
        setState((prevState) => ({
            ...prevState,
            selectedItem: clone,
            isUploadingImages: false,
        }));
    };

    const onDeleteImages = async (selectedImages, toggleImagesDeletionPrompt) => {
        const clone = cloneDeep(state.selectedItem);
        clone.current.pictures = [...clone.current.pictures.filter((_, index) => !selectedImages.includes(index))];

        setState((prevState) => ({
            ...prevState,
            selectedItem: clone,
        }));
        toggleImagesDeletionPrompt(true);
    };

    const checkReconItems = () => {
        const { categories } = state;
        const result = [];

        if (
            categories.reduce((a, b) => a + b.items.filter((e) => e.current?.repair === true).length, 0) === 0
        ) {
            ModalUtils.errorMessage(null, 'At least one item to repair needed');
            return;
        }

        categories.forEach((cat) => {
            const { items } = cat;

            items.forEach((it) => {
                result.push({
                    reconInspectionItemId: it.reconInspectionItemId,
                    name: it.name,
                    pass: it.current?.pass || false,
                    soon: it.current?.soon || false,
                    fail: it.current?.fail || false,
                    repair: it.current?.repair || false,
                    jobTypes: it.current?.jobTypes || [],
                    comment: it.current?.comment,
                    pictures: (it.current?.pictures || []).map((el) => el.imageURL),
                });
            });
        });

        updateInspectionItems(result);
    };

    const allDone = inspectionComplete || (invoiceNumber > 0 && state.savedItemsLoaded);
    const getColumns = () => [
        {
            label: 'Name',
            dataKey: 'name',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;

                return (
                    <span className={isCategory ? classes.categoryText : ''}>{record.name}</span>
                );
            },
        },
        {
            label: 'Pass',
            dataKey: 'pass',
            width: 60,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    const { reconInspectionItemId, current } = record;

                    return (
                        <StatusCheck
                            className={classes.passCheck}
                            checked={current?.pass || false}
                            property="pass"
                            inspectionComplete={allDone}
                            reconInspectionItemId={reconInspectionItemId}
                            onChange={onChange}
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Pass</span>
                );
            },
        },
        {
            label: 'Soon',
            dataKey: 'soon',
            width: 60,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    const { reconInspectionItemId, current } = record;

                    return (
                        <StatusCheck
                            className={classes.soonCheck}
                            checked={current?.soon || false}
                            property="soon"
                            inspectionComplete={allDone}
                            reconInspectionItemId={reconInspectionItemId}
                            onChange={onChange}
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Soon</span>
                );
            },
        },
        {
            label: 'Fail',
            dataKey: 'fail',
            width: 60,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    const { reconInspectionItemId, current } = record;

                    return (
                        <StatusCheck
                            className={classes.failCheck}
                            checked={current?.fail || false}
                            property="fail"
                            inspectionComplete={allDone}
                            reconInspectionItemId={reconInspectionItemId}
                            onChange={onChange}
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Fail</span>
                );
            },
        },
        {
            label: 'Repair',
            dataKey: 'repair',
            width: 80,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    const { reconInspectionItemId, current } = record;

                    return (
                        <RepairSwitch
                            disabled={current?.fail ? !current.fail : true}
                            checked={current?.repair || false}
                            inspectionComplete={allDone}
                            reconInspectionItemId={reconInspectionItemId}
                            onChange={onChange}
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Repair</span>
                );
            },
        },
        {
            label: 'Job Types',
            dataKey: 'jobTypes',
            width: 250,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    const { reconInspectionItemId, current } = record;

                    return (
                        <JobTypesSelect
                            classes={classes}
                            disabled={!current?.repair || allDone}
                            currentValue={current?.jobTypes || []}
                            jobTypes={state.jobTypes}
                            reconInspectionItemId={reconInspectionItemId}
                            onChange={onChange}
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Job Types</span>
                );
            },
        },
        {
            label: 'Comments',
            dataKey: 'comments',
            width: 250,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    const { reconInspectionItemId, current } = record;

                    return (
                        <CommentsField
                            className={classes.textFieldSmall}
                            currentValue={current?.comment || ''}
                            inspectionComplete={allDone}
                            reconInspectionItemId={reconInspectionItemId}
                            onChange={onChange}
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Comments</span>
                );
            },
        },
        {
            label: 'Pictures',
            dataKey: 'pictures',
            width: 100,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    const { current } = record;
                    const totalPictures = current?.pictures?.length || 0;

                    return (
                        <Button
                            size="small"
                            className={clsx(classes.containedSecondaryInfo, classes.uploadButton, totalPictures > 0 ? classes.greenUploadButton : '')}
                            onClick={() => togglePicturesForm(record)}
                        >
                            {`View (${totalPictures})`}
                        </Button>
                    );
                }

                return (
                    <span className={classes.categoryText}>Pictures</span>
                );
            },
        },
    ];

    return (
        <div className={classes.main}>
            <If condition={isMobile}>
                {state.categories.map((cat, index) => {
                    if (!ArrayUtils.isNotEmpty(cat.items)) return null;

                    return (
                        <InspectionCategoryPanel
                            key={`${cat.name}-${index}`}
                            isMPI
                            canWrite
                            data={cat}
                            onChange={onChange}
                            togglePicturesForm={togglePicturesForm}
                            inspectionComplete={allDone}
                            jobTypes={state.jobTypes}
                        />
                    );
                })}
            </If>
            <If condition={!isMobile}>
                <div className={classes.tableContainer}>
                    <VirtualTable
                        loading={loadingInspectionListItems}
                        rowHeight={35}
                        totalRecords={formattedData.length}
                        data={formattedData}
                        columns={getColumns()}
                        rowStyleFormat={rowStyleFormat}
                    />
                </div>
            </If>
            <If condition={!allDone}>
                <div className={classes.actions}>
                    <Button
                        disabled={
                            loadingInspectionListItems
                            || loadingJobTypes
                        }
                        size="small"
                        className={classes.containedSecondaryInfo}
                        onClick={checkReconItems}
                    >
                        Finish
                    </Button>
                </div>
            </If>
            <InspectionImagesDialog
                isPicturesFormOpen={state.isPicturesFormOpen}
                isUploadingImages={state.isUploadingImages}
                openImagePreview={state.openImagePreview}
                addImages={onAddImages}
                canWrite
                togglePicturesForm={togglePicturesForm}
                selectedItemImages={selectedItemImages}
                onOpenImage={toggleImagePreview}
                photoIndex={state.photoIndex}
                toggleImagePreview={toggleImagePreview}
                setPhotoIndex={onSetPhotoIndex}
                onDeleteImages={onDeleteImages}
                moveType={moveType}
                isApproved={allDone}
            />
        </div>
    );
};

MultiPointInspection.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    invoiceNumber: PropTypes.number.isRequired,
    inspectionComplete: PropTypes.bool.isRequired,
    updateInspectionItems: PropTypes.func.isRequired,
};

export default MultiPointInspection;
