/* eslint-disable react/prop-types */
import React, { useReducer, useEffect } from 'react';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles, Checkbox,
    TextField, Button,
    useMediaQuery,
} from '@material-ui/core';
import { cloneDeep } from 'lodash';
import useImagesActions from 'components/modules/inventory/hooks/useImagesActions';
import useReconActions from 'components/modules/inventory/hooks/useReconActions';
import { ReconStatus } from 'utils/enum/InventoryEnum';
import { FetchPolicy } from 'utils/enum/Core';
import Switch from '@material-ui/core/Switch';
import { useTheme } from '@material-ui/core/styles';
import ModalUtils from 'utils/ModalUtils';
import ArrayUtils from 'lib/ArrayUtils';
import ButtonStyles from 'styles/theme/Button';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import VirtualTable from 'components/widgets/VirtualTable';
import InputNumber from 'components/widgets/InputNumber';
import If from 'components/widgets/conditional/If';
import InspectionImagesDialog from 'components/modules/inventory/read/InspectionImagesDialog';
import InspectionCategoryPanel from 'components/modules/inventory/read/panels/InspectionCategoryPanel';
import ReconActionsBar from 'components/modules/inventory/read/ReconActionsBar';

const INIT_STATE = {
    categories: [],
    status: null,
    note: null,
    inspector: null,
    inspectors: [],
    isPicturesFormOpen: false,
    isUploadingImages: false,
    isSavingInspectionItems: false,
    selectedItem: null,
    selectedItemImages: null,
    openImagePreview: false,
    photoIndex: -1,
    itemsForRepair: null,
};

const ACTION_TYPES = {
    SET_CATEGORIES: 'setCategories',
    INIT_RECON_STATUS: 'initReconStatus',
    TOGGLE_IS_PICTURES_FORM_OPEN: 'toggleIsPicturesFormOPen',
    TOGGLE_UPLOADING_IMAGES: 'toggleUploadingImages',
    SET_SELECTED_ITEM_IMAGES: 'setSelectedItemImages',
    TOGGLE_IMAGE_PREVIEW: 'toggleImagePreview',
    TOGGLE_IS_SAVING_INSPECTION_ITEMS: 'toggleIsSavingInspectionItems',
    SET_PHOTO_INDEX: 'setPhotoIndex',
    SET_RECON_STATUS: 'setReconStatus',
    SET_CATEGORIES_AND_ITEMS_FOR_REPAIR: 'setCategoriesAndItemsForRepair',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_CATEGORIES:
        return {
            ...state,
            categories: action.value,
        };
    case ACTION_TYPES.INIT_RECON_STATUS:
        const {
            status,
            note,
            currentInspector,
            inspectors,
        } = action.value;

        return {
            ...state,
            status,
            note,
            inspector: currentInspector?.userID,
            inspectors,
        };
    case ACTION_TYPES.SET_RECON_STATUS:
        return {
            ...state,
            status: action.value.status,
            inspector: action.value.inspector,
        };
    case ACTION_TYPES.TOGGLE_IS_PICTURES_FORM_OPEN:
        return {
            ...state,
            isPicturesFormOpen: !state.isPicturesFormOpen,
            selectedItem: action.value,
            selectedItemImages: (!action.value ? null : state.selectedItemImages),
        };
    case ACTION_TYPES.TOGGLE_UPLOADING_IMAGES:
        return {
            ...state,
            isUploadingImages: !state.isUploadingImages,
        };
    case ACTION_TYPES.SET_SELECTED_ITEM_IMAGES:
        return {
            ...state,
            selectedItemImages: action.value,
        };
    case ACTION_TYPES.TOGGLE_IMAGE_PREVIEW:
        return {
            ...state,
            openImagePreview: !state.openImagePreview,
            photoIndex: action.value,
        };
    case ACTION_TYPES.TOGGLE_IS_SAVING_INSPECTION_ITEMS:
        return {
            ...state,
            isSavingInspectionItems: !state.isSavingInspectionItems,
        };
    case ACTION_TYPES.SET_PHOTO_INDEX:
        return {
            ...state,
            photoIndex: action.value,
        };
    case ACTION_TYPES.SET_CATEGORIES_AND_ITEMS_FOR_REPAIR:
        const { categories, itemsForRepair } = action.value;

        return {
            ...state,
            categories,
            itemsForRepair,
        };
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        position: 'relative',
        minWidth: '1020px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginTop: '20px',
            minWidth: 'initial',
        },
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '20px',
        height: '73vh',
        width: '100%',
        minWidth: '900px',
        overflow: 'hidden',
        '& .ReactVirtualized__Table__headerRow': {
            display: 'none',
        },
        '& .ReactVirtualized__Grid': {
            paddingBottom: '100px',
        },
        '& .ReactVirtualized__Table__rowColumn': {
            padding: '7px 0px',
            fontSize: '13px',
            color: theme.palette.text.outerSpace,
            justifyContent: 'left',
            display: 'flex',
            '& > span': {
                fontSize: '13px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
    },
    categoryText: {
        color: theme.palette.text.white,
        fontWeight: 'bold',
    },
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
    uploadButton: {
        fontSize: '12px',
        borderRadius: '0px',
    },
    greenUploadButton: {
        backgroundColor: `${theme.palette.background.green} !important`,
    },
    passCheck: {
        color: theme.palette.text.green,
    },
    failCheck: {
        color: theme.palette.text.red,
    },
}));

const InspectionListTab = ({
    stockNumber,
    canWrite,
    canApprove,
    saveRepairItem,
    maximumReconCost,
}) => {
    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        moveType, addImages, deleteImages, setPhotoIndex,
    } = useImagesActions();
    const { changeReconStatus } = useReconActions();
    const [state, dispatch] = useReducer(reducer, INIT_STATE);
    const {
        categories,
        status,
        note,
        isPicturesFormOpen,
        isUploadingImages,
        isSavingInspectionItems,
        selectedItem,
        selectedItemImages,
        openImagePreview,
        photoIndex,
        inspector,
        inspectors,
        itemsForRepair,
    } = state;

    const {
        data: statusData,
        loading: loadingStatus,
        error: errorStatus,
    } = useQuery(InventoryQuery.GET_RECON_STATUS, {
        variables: {
            stockNumber,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: inspectionListItemsData,
        loading: loadingInspectionListItems,
        error: errorInspectionListItems,
        refetch,
    } = useQuery(InventoryQuery.GET_INSPECTION_LIST_ITEMS, {
        variables: {
            stockNumber,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getImages] = useLazyQuery(InventoryQuery.GET_VEHICLE_RECON_ITEM_IMAGES, {
        onCompleted: (response) => {
            if (response) {
                const images = response.getVehicleReconItemImages;
                dispatch({
                    type: ACTION_TYPES.SET_SELECTED_ITEM_IMAGES,
                    value: images,
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const setRepairItemIds = (repairableItems, cats) => {
        const clone = cloneDeep(cats || categories);

        clone.forEach((cat) => {
            const { items } = cat;

            items.forEach((item) => {
                const { reconInspectionItemId } = item;
                const element = repairableItems.find((ri) => ri.reconInspectionItemId === reconInspectionItemId);

                if (element) {
                    // eslint-disable-next-line no-param-reassign
                    item.vehicleRepairItemId = element.vehicleRepairItemId;
                }
            });
        });

        dispatch({
            type: ACTION_TYPES.SET_CATEGORIES,
            value: clone,
        });
    };

    const getItemsToRepair = (cats = []) => {
        const items = [];
        cats.forEach((cat) => {
            if (ArrayUtils.isNotEmpty(cat.items)) {
                cat.items.forEach((item) => {
                    if (item.current && item.current.repair) items.push(item);
                });
            }
        });

        return items;
    };

    const formatForInspectionSheet = (cats = []) => {
        let output = [];
        cats.forEach((cat) => {
            if (ArrayUtils.isNotEmpty(cat.items)) {
                output.push({
                    reconInspectionCategoryId: cat.reconInspectionCategoryId,
                    name: cat.name,
                    order: cat.order,
                    active: cat.active,
                });
                output = [...output, ...cat.items];
            }
        });

        return output;
    };

    const formattedData = formatForInspectionSheet(categories);
    const rowStyleFormat = (row) => {
        const { index } = row;
        if (index < 0) return;

        const record = formattedData[index];
        const isCategory = 'reconInspectionCategoryId' in record;
        if (!isCategory) return;

        // eslint-disable-next-line consistent-return
        return {
            pointerEvents: 'none',
            backgroundColor: theme.palette.background.darkPurple,
        };
    };

    useEffect(() => {
        if (errorInspectionListItems) {
            ModalUtils.errorMessage(errorInspectionListItems?.graphQLErrors);
        }

        if (!loadingInspectionListItems) {
            const data = (inspectionListItemsData?.getInspectionListItems ?? [])
                .filter((item) => !item.forTechnicians);

            dispatch({
                type: ACTION_TYPES.SET_CATEGORIES_AND_ITEMS_FOR_REPAIR,
                value: {
                    categories: data,
                    itemsForRepair: getItemsToRepair(data),
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingInspectionListItems, errorInspectionListItems]);

    useEffect(() => {
        if (itemsForRepair && itemsForRepair.length > 0) {
            try {
                client.query({
                    query: InventoryQuery.GET_REPAIR_ITEMS,
                    variables: { stockNumber },
                    fetchPolicy: FetchPolicy.NETWORK_ONLY,
                }).then(async (response) => {
                    const { data } = response;

                    const result = data?.getRepairItems;
                    if (result && result.length > 0) {
                        const repairableItems = [];
                        result.forEach((e) => {
                            const { vehicleRepairItemId, inspectionItem: { reconItem: { reconInspectionItemId } } } = e;

                            repairableItems.push({
                                vehicleRepairItemId,
                                reconInspectionItemId,
                            });
                        });

                        setRepairItemIds(repairableItems);
                    }
                });
            } catch (error) {
                ModalUtils.errorMessage(null, error.message);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsForRepair]);

    useEffect(() => {
        if (errorStatus) {
            ModalUtils.errorMessage(errorStatus?.graphQLErrors);
        }

        if (!loadingStatus) {
            const data = statusData?.getReconStatus;
            if (data) {
                dispatch({
                    type: ACTION_TYPES.INIT_RECON_STATUS,
                    value: data,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingStatus, errorStatus]);

    const saveReconItems = async (showSucessMessage = true, savedItem) => {
        if (status === ReconStatus.INSPECTION_APPROVED || status === ReconStatus.REPAIRS_COMPLETE || !canWrite || isSavingInspectionItems) return;

        try {
            dispatch({
                type: ACTION_TYPES.TOGGLE_IS_SAVING_INSPECTION_ITEMS,
            });

            const references = [];
            const itemsToUpdate = [];
            const clone = cloneDeep(categories);
            let anyNew = false;
            clone.forEach((cat) => {
                const { items } = cat;

                items.forEach((item) => {
                    const { reconInspectionItemId, name, current } = item;

                    if (current && (!savedItem || (savedItem && savedItem.reconInspectionItemId !== reconInspectionItemId))) {
                        if (!current.vehicleInspectionItemId) anyNew = true;
                        references.push(item);

                        itemsToUpdate.push({
                            vehicleInspectionItemId: current.vehicleInspectionItemId || null,
                            reconInspectionItemId,
                            reconInspectionItemName: name,
                            pass: current.pass || false,
                            fail: current.fail || false,
                            part: current.part || false,
                            repair: current.repair || false,
                            estimation: current.estimation || null,
                            comment: current.comment || null,
                        });
                    }
                });
            });

            const { data } = await client.mutate({
                mutation: InventoryMutation.SAVE_VEHICLE_RECON_ITEMS,
                variables: {
                    stockNumber,
                    items: itemsToUpdate,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (showSucessMessage) ModalUtils.successMessage(null, 'Sheet saved successfully');
            if (anyNew) {
                const response = data?.saveVehicleReconItems;
                response.forEach((el) => {
                    const { vehicleInspectionItemId, reconInspectionItemId } = el;

                    const item = references.find((ref) => ref.reconInspectionItemId === reconInspectionItemId);
                    if (item) {
                        item.current.vehicleInspectionItemId = vehicleInspectionItemId;
                    }
                });

                dispatch({
                    type: ACTION_TYPES.SET_CATEGORIES,
                    value: clone,
                });
            }

            const repairItemstoUpdate = [];
            const repairableItemsIds = [];
            references.forEach((item) => {
                const { vehicleRepairItemId, reconInspectionItemId, current: { vehicleInspectionItemId, comment, repair } } = item;

                if (
                    (repair && !vehicleRepairItemId)
                    || (!repair && vehicleRepairItemId)
                ) {
                    repairableItemsIds.push({
                        vehicleRepairItemId: null,
                        reconInspectionItemId,
                    });

                    repairItemstoUpdate.push({
                        vehicleRepairItemId,
                        vehicleInspectionItemId,
                        order: repair && !vehicleRepairItemId ? 0 : -1,
                        assigneeId: null,
                        vendorId: null,
                        startOn: null,
                        completeOn: null,
                        comment,
                        active: (repair && !vehicleRepairItemId),
                    });
                }
            });

            if (repairItemstoUpdate.length > 0) {
                const ids = await ArrayUtils.processPromisesInSequence(repairItemstoUpdate, saveRepairItem);
                ids.forEach((id, index) => {
                    const element = repairItemstoUpdate[index];
                    if (element.active) repairableItemsIds[index].vehicleRepairItemId = id;
                });

                setRepairItemIds(repairableItemsIds, clone);
            }

            dispatch({
                type: ACTION_TYPES.TOGGLE_IS_SAVING_INSPECTION_ITEMS,
            });
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const onChange = (name, value, itemId) => {
        if (status === ReconStatus.INSPECTION_APPROVED || status === ReconStatus.REPAIRS_COMPLETE || !canWrite) return;

        try {
            const clone = cloneDeep(categories);
            for (let x = 0; x < clone.length; x += 1) {
                const cat = clone[x];
                const { items } = cat;

                const index = items.findIndex((item) => item.reconInspectionItemId === itemId);
                if (index >= 0) {
                    let others = {};
                    switch (`${name}-${value}`) {
                    case 'pass-true':
                        others = {
                            fail: false,
                            part: false,
                            repair: false,
                            estimation: 0,
                        };
                        break;
                    case 'fail-true':
                        others = {
                            pass: false,
                        };
                        break;
                    case 'fail-false':
                        others = {
                            pass: false,
                            part: false,
                            repair: false,
                            estimation: 0,
                        };
                        break;
                    default:
                        break;
                    }

                    items[index].current = {
                        ...(items[index].current || {}),
                        [name]: value,
                        ...others,
                    };
                    break;
                }
            }

            dispatch({
                type: ACTION_TYPES.SET_CATEGORIES,
                value: clone,
            });
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const togglePicturesForm = async (item) => {
        if (!item) {
            dispatch({
                type: ACTION_TYPES.TOGGLE_IS_PICTURES_FORM_OPEN,
                value: null,
            });

            refetch();
            return;
        }

        const clone = cloneDeep(item);
        if (!clone.current?.vehicleInspectionItemId) {
            let payload = {
                vehicleInspectionItemId: null,
                reconInspectionItemId: clone.reconInspectionItemId,
                reconInspectionItemName: clone.name,
                pass: false,
                fail: false,
                part: false,
                repair: false,
                estimation: null,
                comment: null,
            };

            if (clone.current) {
                payload = {
                    ...payload,
                    ...clone.current,
                };
            }

            const { data } = await client.mutate({
                mutation: InventoryMutation.SAVE_VEHICLE_RECON_ITEMS,
                variables: {
                    stockNumber,
                    items: [payload],
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const response = data?.saveVehicleReconItems;
            const { vehicleInspectionItemId } = response[0];
            clone.current = {
                ...payload,
                vehicleInspectionItemId,
            };
        }

        dispatch({
            type: ACTION_TYPES.TOGGLE_IS_PICTURES_FORM_OPEN,
            value: clone,
        });

        saveReconItems(false, clone);
        getImages({
            variables: {
                vehicleInspectionItemId: clone.current.vehicleInspectionItemId,
            },
        });
    };

    const onAddImages = async (files) => {
        const { current } = selectedItem;
        const dispatcherPayload = {
            type: ACTION_TYPES.TOGGLE_UPLOADING_IMAGES,
        };

        addImages(
            files,
            dispatch,
            dispatcherPayload,
            null,
            'vehicleInspectionItemId',
            current.vehicleInspectionItemId,
            stockNumber,
            InventoryMutation.UPLOAD_VEHICLE_RECON_ITEM_IMAGE,
            'uploadVehicleReconItemImage',
            InventoryMutation.SAVE_VEHICLE_RECON_ITEM_IMAGES,
            getImages,
        );
    };

    const onSetPhotoIndex = (type) => setPhotoIndex(
        type,
        photoIndex,
        selectedItemImages,
        dispatch,
        ACTION_TYPES.SET_PHOTO_INDEX,
    );

    const onOpenImage = (index) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_IMAGE_PREVIEW,
            value: index,
        });
    };

    const onDeleteImages = async (selectedImages, toggleImagesDeletionPrompt) => {
        const { current } = selectedItem;
        const payload = {
            variables: {
                vehicleInspectionItemId: current.vehicleInspectionItemId,
            },
        };

        deleteImages(
            selectedItemImages,
            'vehicleInspectionItemImageId',
            InventoryMutation.REMOVE_VEHICLE_RECON_ITEM_IMAGES,
            selectedImages,
            toggleImagesDeletionPrompt,
            getImages,
            payload,
        );
    };

    const toggleImagePreview = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_IMAGE_PREVIEW,
        });
    };

    const totalEstimate = formattedData.filter((el) => el.current?.repair).reduce((a, b) => a + (b.current?.estimation || 0), 0);
    const onChangeReconStatus = async (nextStatus, ins = null, inputNote, isApproving = false, isReapprovalRequest = false) => {
        const data = await changeReconStatus(
            stockNumber,
            nextStatus,
            ins,
            inputNote,
            isApproving,
            totalEstimate,
            maximumReconCost,
            saveReconItems,
            isReapprovalRequest,
        );

        if (data) {
            dispatch({
                type: ACTION_TYPES.SET_RECON_STATUS,
                value: data,
            });
        }
    };

    const StatusSelector = React.memo(({
        disabled,
        style = '',
        currentValue,
        inspectionItemId,
        field,
    }) => (
        <Checkbox
            color="default"
            className={style}
            disabled={disabled}
            checked={currentValue}
            onChange={(_, value) => onChange(field, value, inspectionItemId)}
        />
    ), (prevProps, nextProps) => prevProps.style === nextProps.style && prevProps.disabled === nextProps.disabled
    && prevProps.currentValue === nextProps.currentValue && prevProps.inspectionItemId === nextProps.inspectionItemId);

    const RepairSwitch = React.memo(({
        disabled,
        currentValue,
        inspectionItemId,
    }) => (
        <Switch
            disabled={disabled}
            checked={currentValue}
            onChange={(_, value) => onChange('repair', value, inspectionItemId)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
        />
    ), (prevProps, nextProps) => prevProps.disabled === nextProps.disabled && prevProps.currentValue === nextProps.currentValue
    && prevProps.inspectionItemId === nextProps.inspectionItemId);

    const EstimateField = React.memo(({
        disabled,
        currentValue,
        inspectionItemId,
    }) => (
        <InputNumber
            disabled={disabled}
            value={currentValue}
            onBlur={(e) => onChange('estimation', Number(String(e.target.value ?? '').replace('$', '')), inspectionItemId)}
            placeholder="$0"
            showCurrency
            min={0}
            decimalScale={0}
            thousandSeparator
            size="sm"
        />
    ), (prevProps, nextProps) => prevProps.disabled === nextProps.disabled && prevProps.currentValue === nextProps.currentValue
    && prevProps.inspectionItemId === nextProps.inspectionItemId);

    const getColumns = () => [
        {
            label: 'Name',
            dataKey: 'name',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;

                return (
                    <span className={isCategory ? classes.categoryText : ''}>{record.name}</span>
                );
            },
        },
        {
            label: 'Pass',
            dataKey: 'pass',
            width: 60,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    if (!canWrite) return null;
                    const { reconInspectionItemId, current } = record;

                    return (
                        <StatusSelector
                            disabled={false}
                            style={classes.passCheck}
                            currentValue={current?.pass || false}
                            inspectionItemId={reconInspectionItemId}
                            field="pass"
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Pass</span>
                );
            },
        },
        {
            label: 'Fail',
            dataKey: 'fail',
            width: 60,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    if (!canWrite) return null;
                    const { reconInspectionItemId, current } = record;

                    return (
                        <StatusSelector
                            disabled={false}
                            style={classes.failCheck}
                            currentValue={current?.fail || false}
                            inspectionItemId={reconInspectionItemId}
                            field="fail"
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Fail</span>
                );
            },
        },
        {
            label: 'Part',
            dataKey: 'part',
            width: 60,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    if (!canWrite) return null;
                    const { reconInspectionItemId, current } = record;

                    return (
                        <StatusSelector
                            disabled={current?.fail ? !current.fail : true}
                            currentValue={current?.part || false}
                            inspectionItemId={reconInspectionItemId}
                            field="part"
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Part</span>
                );
            },
        },
        {
            label: 'Repair',
            dataKey: 'repair',
            width: 80,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    if (!canWrite) return null;
                    const { reconInspectionItemId, current } = record;

                    return (
                        <RepairSwitch
                            disabled={current?.fail ? !current.fail : true}
                            currentValue={current?.repair || false}
                            inspectionItemId={reconInspectionItemId}
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Repair</span>
                );
            },
        },
        {
            label: 'Comments',
            dataKey: 'comments',
            width: 250,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    if (!canWrite) return null;
                    const { reconInspectionItemId, current } = record;

                    return (
                        <TextField
                            disabled={status === ReconStatus.INSPECTION_APPROVED || status === ReconStatus.REPAIRS_COMPLETE}
                            className={classes.textFieldSmall}
                            value={current?.comment || ''}
                            name="comment"
                            onChange={({ target: { name, value } }) => onChange(name, value, reconInspectionItemId)}
                            variant="outlined"
                            size="small"
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Comments</span>
                );
            },
        },
        {
            label: 'Estimate',
            dataKey: 'estimate',
            width: 120,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    if (!canWrite) return null;
                    const { reconInspectionItemId, current } = record;

                    return (
                        <EstimateField
                            disabled={(current?.fail ? !current.fail : true)
                                || status === ReconStatus.INSPECTION_APPROVED
                                || status === ReconStatus.REPAIRS_COMPLETE}
                            currentValue={current?.estimation || 0}
                            inspectionItemId={reconInspectionItemId}
                        />
                    );
                }

                return (
                    <span className={classes.categoryText}>Estimate</span>
                );
            },
        },
        {
            label: 'Pictures',
            dataKey: 'pictures',
            width: 100,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const isCategory = 'reconInspectionCategoryId' in record;
                if (!isCategory) {
                    if (!canWrite) return null;
                    const { images } = record;

                    return (
                        <Button
                            size="small"
                            className={clsx(classes.containedSecondaryInfo, classes.uploadButton, (images || 0) > 0 ? classes.greenUploadButton : '')}
                            onClick={() => togglePicturesForm(record)}
                        >
                            {`View (${images || 0})`}
                        </Button>
                    );
                }

                return (
                    <span className={classes.categoryText}>Pictures</span>
                );
            },
        },
    ];

    const defaultInspector = inspector
        || (!canApprove ? inspectors.find((el) => el.default)?.userID : null);
    const inspectorOptions = inspectors
        .map((item) => ({
            value: item.userID,
            label: `${item.firstName} ${item.lastName}`,
        }));

    return !status ? null : (
        <div className={classes.main}>
            <If condition={isMobile}>
                {categories.map((cat, index) => {
                    if (!ArrayUtils.isNotEmpty(cat.items)) return null;

                    return (
                        <InspectionCategoryPanel
                            key={`${cat.name}-${index}`}
                            data={cat}
                            canWrite={canWrite}
                            onChange={onChange}
                            togglePicturesForm={togglePicturesForm}
                            isApproved={status === ReconStatus.INSPECTION_APPROVED || status === ReconStatus.REPAIRS_COMPLETE}
                        />
                    );
                })}
            </If>
            <If condition={!isMobile}>
                <div className={classes.tableContainer}>
                    <VirtualTable
                        loading={loadingInspectionListItems}
                        rowHeight={35}
                        totalRecords={formattedData.length}
                        data={formattedData}
                        columns={getColumns()}
                        rowStyleFormat={rowStyleFormat}
                    />
                </div>
            </If>
            <If condition={formattedData.length > 0}>
                <ReconActionsBar
                    isSavingInspectionItems={isSavingInspectionItems}
                    canApprove={canApprove}
                    status={status}
                    note={note}
                    inspector={inspector}
                    defaultInspector={defaultInspector}
                    inspectorOptions={inspectorOptions}
                    totalEstimate={totalEstimate}
                    maximumReconCost={maximumReconCost}
                    onChangeReconStatus={onChangeReconStatus}
                    saveReconItems={saveReconItems}
                />
            </If>
            <InspectionImagesDialog
                isPicturesFormOpen={isPicturesFormOpen}
                isUploadingImages={isUploadingImages}
                openImagePreview={openImagePreview}
                addImages={onAddImages}
                canWrite={canWrite}
                togglePicturesForm={togglePicturesForm}
                selectedItemImages={selectedItemImages}
                onOpenImage={onOpenImage}
                photoIndex={photoIndex}
                toggleImagePreview={toggleImagePreview}
                setPhotoIndex={onSetPhotoIndex}
                onDeleteImages={onDeleteImages}
                moveType={moveType}
                isApproved={status === ReconStatus.INSPECTION_APPROVED || status === ReconStatus.REPAIRS_COMPLETE}
            />
        </div>
    );
};

InspectionListTab.propTypes = {
    stockNumber: PropTypes.number,
    canWrite: PropTypes.bool.isRequired,
    canApprove: PropTypes.bool.isRequired,
    saveRepairItem: PropTypes.func.isRequired,
    maximumReconCost: PropTypes.number,
};

InspectionListTab.defaultProps = {
    stockNumber: 0,
    maximumReconCost: 0,
};

export default InspectionListTab;
