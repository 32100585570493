/* eslint-disable no-param-reassign */
/* eslint-disable import/no-named-default */
import React, { useReducer, useEffect, useRef } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';
import {
    makeStyles, Button, Grid,
    FormControl, FormControlLabel,
    Radio, RadioGroup, Divider,
    Dialog, DialogContent,
    DialogActions, Select,
    MenuItem, Checkbox, ListItemText,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LotQuery from 'services/graphQL/query/LotQuery';
import CatalogEnum from 'utils/enum/CatalogEnum';
import CatalogQuery from 'services/graphQL/query/CatalogQuery';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import { InventoryCategory } from 'utils/enum/InventoryEnum';
import { ALL_LOTS, FetchPolicy } from 'utils/enum/Core';
import { Col, Form } from 'react-bootstrap';
import ArrayUtils from 'lib/ArrayUtils';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import LotsCategory from 'utils/enum/LotsCategory';
import If from 'components/widgets/conditional/If';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { default as SelectWidget } from 'components/widgets/Select';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import CatalogManager from 'components/modules/settings/inventory/CatalogManager';
import InputNumber from 'components/widgets/InputNumber';
import VirtualTable from 'components/widgets/VirtualTable';

// Icons
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
        '& > div > button': {
            marginRight: '10px',
        },
    },
    boxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    paddingLeft25: {
        paddingLeft: '25px',
        paddingRight: '25px',
    },
    otherActions: {
        '& > button': {
            marginTop: '2px',
        },
        '& > button:nth-child(1), & > button:nth-child(2)': {
            marginRight: '10px',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            '& > button:nth-child(1), & > button:nth-child(2)': {
                marginBottom: '5px',
                width: '100%',
            },
            '& > button:nth-child(3)': {
                width: '100%',
            },
        },
    },
    labels: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
    },
    subLabel: {
        fontSize: '12px',
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        height: '380px',
        overflow: 'hidden',
        '& > div': {
            overflow: 'hidden',
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button:nth-child(1)': {
            marginRight: '2px',
        },
        '& > button': {
            minWidth: '32px',
            '& .MuiButton-startIcon': {
                marginRight: '0px',
            },
        },
    },
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '20px 10px',
    },
    select: {
        width: '100%',
        border: `1px solid ${theme.palette.border.ghost}`,
        borderRadius: '0.25rem',
        height: '38px',
        '& > div': {
            paddingLeft: '10px !important',
            paddingRight: '10px !important',
        },
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
        },
    },
    dialogLabels: {
        fontSize: '14px',
    },
    input: {
        fontSize: '14px',
        resize: 'none',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '14px',
            lineHeight: '1.4',
        },
    },
    fieldContainer: {
        '& > .select-bootstrap': {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        '& > .max-cost': {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            '& > input': {
                marginRight: '10px',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    marginBottom: '10px',
                    marginRight: 0,
                },
            },
        },
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    PaperProps: {
        style: {
            maxHeight: 200,
            width: 250,
        },
    },
    getContentAnchorEl: null,
};

const INIT_STATE = {
    currentFilter: 0,
    categories: [],
    jobTypes: [],
    isAddFormOpen: false,
    selectedItem: {},
    isRemoveWarningOpen: false,
    isRestoringItem: false,
    isCatalogManagerFormOpen: false,
    isJobTypesCatalog: false,
    inspectors: [],
    defaultReconCost: 0,
};

const ACTION_TYPES = {
    SET_CURRENT_FILTER: 'setCurrentFilter',
    SET_CATEGORIES: 'setCategories',
    SET_JOB_TYPES: 'setJobTypes',
    TOGGLE_ADD_FORM: 'toggleAddForm',
    CHANGE_FIELD_IN_SELECTED: 'changeFieldInSelected',
    ADD_ITEM: 'addItem',
    ADD_CATEGORY: 'addCategory',
    MODIFY_ITEM: 'modifyItem',
    TOGGLE_DISPLAY_WARNING: 'toggleDisplayWarning',
    SET_SELECTED_ITEM: 'setSelectedItem',
    TOGGLE_CATALOG_MANAGER_FORM: 'toggleCatalogManagerForm',
    SET_INSPECTORS: 'setInspectors',
    SET_DEFAULT_INSPECTOR: 'setDefaultInspector',
    SET_DEFAULT_RECON_COST: 'setDefaultReconCosto',
};

const reducer = (state, action = {}) => {
    let clone = null;
    let index = -1;

    switch (action.type) {
    case ACTION_TYPES.SET_CURRENT_FILTER:
        return {
            ...state,
            currentFilter: action.value,
        };
    case ACTION_TYPES.SET_CATEGORIES:
        const cats = cloneDeep(action.value);

        return {
            ...state,
            categories: cats.map((c) => {
                const { items } = c;
                items.forEach((item) => {
                    item.jobTypes = item.jobTypeIds?.split(',')?.map((id) => Number(id)) || [];
                    delete item.jobTypeIds;
                });

                return c;
            }),
        };
    case ACTION_TYPES.SET_JOB_TYPES:
        return {
            ...state,
            jobTypes: action.value,
        };
    case ACTION_TYPES.SET_SELECTED_ITEM:
        return {
            ...state,
            isRestoringItem: true,
            selectedItem: action.value,
        };
    case ACTION_TYPES.TOGGLE_ADD_FORM:
        const item = action.value;

        if (item) {
            return {
                ...state,
                selectedItem: item,
                isAddFormOpen: !state.isAddFormOpen,
            };
        }

        return {
            ...state,
            selectedItem: !state.isAddFormOpen ? {} : state.selectedItem,
            isAddFormOpen: !state.isAddFormOpen,
        };
    case ACTION_TYPES.TOGGLE_DISPLAY_WARNING:
        return {
            ...state,
            selectedItem: action.value,
            isRemoveWarningOpen: !state.isRemoveWarningOpen,
        };
    case ACTION_TYPES.TOGGLE_CATALOG_MANAGER_FORM:
        return {
            ...state,
            isCatalogManagerFormOpen: !state.isCatalogManagerFormOpen,
            isJobTypesCatalog: action.value,
        };
    case ACTION_TYPES.CHANGE_FIELD_IN_SELECTED:
        const { name, value } = action.value;
        clone = cloneDeep(state.selectedItem);

        if (name === 'category') {
            clone[name] = {
                ...(clone[name] || {}),
                reconInspectionCategoryId: value,
            };
        } else {
            clone[name] = value;
        }

        return {
            ...state,
            selectedItem: clone,
        };
    case ACTION_TYPES.ADD_ITEM:
        const reconInspectionItemId = action.value;
        const { category: { reconInspectionCategoryId }, name: itemName } = state.selectedItem;
        clone = cloneDeep(state.categories);

        index = clone.findIndex((cat) => cat.reconInspectionCategoryId === reconInspectionCategoryId);
        if (index >= 0) {
            const items = [
                ...(clone[index]?.items || []),
                {
                    reconInspectionItemId,
                    name: itemName,
                    active: true,
                },
            ];

            items.sort((a, b) => a.name.localeCompare(b.name));
            clone[index] = {
                ...clone[index],
                items,
            };
        }

        return {
            ...state,
            categories: clone,
            selectedItem: {},
        };
    case ACTION_TYPES.ADD_CATEGORY:
        clone = cloneDeep(state.categories);
        clone.push({
            reconInspectionCategoryId: action.value.id,
            name: action.value.name,
            order: action.value.order,
            active: true,
            items: [],
        });

        return {
            ...state,
            categories: clone,
            selectedItem: {
                ...state.selectedItem,
                category: { reconInspectionCategoryId: action.value.id },
            },
        };
    case ACTION_TYPES.MODIFY_ITEM:
        const { category } = state.selectedItem;
        clone = cloneDeep(state.categories);

        index = clone.findIndex((cat) => cat?.reconInspectionCategoryId === category?.reconInspectionCategoryId);
        if (index >= 0) {
            const items = [...(clone[index]?.items || [])];
            const itemIndex = items.findIndex((it) => it.reconInspectionItemId === state.selectedItem.reconInspectionItemId);
            const modifiedItem = {
                reconInspectionItemId: state.selectedItem.reconInspectionItemId,
                name: state.selectedItem.name,
                jobTypes: state.selectedItem.jobTypes,
                active: state.selectedItem.active,
            };

            const removingOrRestoring = (state.isRestoringItem || state.isRemoveWarningOpen);
            if (itemIndex >= 0 && !removingOrRestoring) {
                items[itemIndex] = modifiedItem;
            } else {
                if (!removingOrRestoring) items.push(modifiedItem);

                const oldCategory = action?.value?.category?.reconInspectionCategoryId || category.reconInspectionCategoryId;
                const oldCategoryIndex = clone.findIndex((cat) => cat.reconInspectionCategoryId === oldCategory);

                if (oldCategoryIndex >= 0) {
                    const oldCategoryItems = [...(clone[oldCategoryIndex]?.items || [])].filter(
                        (el) => el.reconInspectionItemId !== state.selectedItem.reconInspectionItemId,
                    );

                    clone[oldCategoryIndex] = {
                        ...clone[oldCategoryIndex],
                        items: oldCategoryItems,
                    };
                }
            }

            if (!removingOrRestoring) {
                items.sort((a, b) => a.name.localeCompare(b.name));
                clone[index] = {
                    ...clone[index],
                    items,
                };
            }
        }

        return {
            ...state,
            categories: clone,
            selectedItem: {},
            isRemoveWarningOpen: false,
            isRestoringItem: false,
        };
    case ACTION_TYPES.SET_INSPECTORS:
        return {
            ...state,
            inspectors: action.value,
        };
    case ACTION_TYPES.SET_DEFAULT_INSPECTOR:
        const userID = action.value;
        const inspectors = state.inspectors.map((inspector) => ({
            ...inspector,
            default: inspector.userID === userID,
        }));

        return {
            ...state,
            inspectors,
        };
    case ACTION_TYPES.SET_DEFAULT_RECON_COST:
        return {
            ...state,
            defaultReconCost: action.value,
        };
    default:
        return state;
    }
};

const ReconSettings = ({ canWrite }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const options = useRef(null);
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, dispatch] = useReducer(reducer, INIT_STATE);
    const {
        currentFilter,
        categories,
        jobTypes,
        isAddFormOpen,
        selectedItem,
        isRemoveWarningOpen,
        isCatalogManagerFormOpen,
        isJobTypesCatalog,
        inspectors,
        defaultReconCost,
    } = state;

    const getItems = (cats) => {
        let output = [];
        cats.forEach((category) => {
            output = [...output, ...([...category.items].map(
                (item) => ({
                    ...item,
                    category: {
                        reconInspectionCategoryId: category.reconInspectionCategoryId,
                        name: category.name,
                    },
                }),
            ))];
        });

        return output;
    };

    const {
        data: settingsData,
        loading: settingsLoading,
        error: settingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.INVENTORY,
            lotName: ALL_LOTS,
            key: [InventoryCategory.INV_DEFAULT_MAX_RECON_COST],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: reconInspectorsData,
        loading: reconInspectorsLoading,
        error: reconInspectorsError,
    } = useQuery(InventoryQuery.GET_RECON_INSPECTORS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getCategories, { loading: loadingCategories, refetch: refetchCategories }] = useLazyQuery(InventoryQuery.GET_INSPECTION_CATEGORIES, {
        onCompleted: (response) => {
            if (response) {
                const data = response.getInspectionCategories;
                dispatch({
                    type: ACTION_TYPES.SET_CATEGORIES,
                    value: data,
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [getJobTypes, { loading: loadingJobTypes, refetch: refetchJobTypes }] = useLazyQuery(CatalogQuery.ENUM_VALUES, {
        onCompleted: (response) => {
            if (response) {
                const data = response.getEnumValues;
                dispatch({
                    type: ACTION_TYPES.SET_JOB_TYPES,
                    value: data,
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [editJobTypes] = useMutation(CatalogQuery.SAVE_ENUM, {
        onCompleted: (response) => {
            if (!response) return;

            ModalUtils.successMessage(null, 'Job Types updated successfully');
            // eslint-disable-next-line no-use-before-define
            toggleCatalogManager(false);
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [saveDefaultInspector, { loading: savingDefaultInspector }] = useMutation(InventoryMutation.SAVE_RECON_DEFAULT_INSPECTOR, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.SET_DEFAULT_INSPECTOR,
                    value: options.current,
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [addItem, { loading: addingItem }] = useMutation(InventoryMutation.ADD_INSPECTION_ITEM, {
        onCompleted: (response) => {
            if (response) {
                const id = response.addInspectionItem;
                dispatch({
                    type: ACTION_TYPES.ADD_ITEM,
                    value: id,
                });

                ModalUtils.successMessage(null, 'Item added successfully');
                // eslint-disable-next-line no-use-before-define
                toggleAddForm();
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [modifyItem, { loading: modifyingItem }] = useMutation(InventoryMutation.SAVE_INSPECTION_ITEM, {
        onCompleted: (response) => {
            if (response?.saveInspectionItem) {
                dispatch({
                    type: ACTION_TYPES.MODIFY_ITEM,
                    value: options.current,
                });

                ModalUtils.successMessage(null, 'Item modified successfully');
                // eslint-disable-next-line no-use-before-define
                if (isAddFormOpen) toggleAddForm();
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [addCategory, { loading: addingCategory }] = useMutation(InventoryMutation.ADD_INSPECTION_CATEGORY, {
        onCompleted: (response) => {
            if (response && !isCatalogManagerFormOpen) {
                const id = response.addInspectionCategory;
                const { name, order } = options.current;
                dispatch({
                    type: ACTION_TYPES.ADD_CATEGORY,
                    value: {
                        id,
                        name,
                        order,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [editCategory] = useMutation(InventoryMutation.SAVE_INSPECTION_CATEGORY, {
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [saveInventorySettings] = useMutation(InventoryMutation.SAVE_INVENTORY_SETTINGS, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'Max Recon Cost saved successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the Max Recon Cost');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    useEffect(() => {
        if (settingsError) {
            ModalUtils.errorMessage(settingsError?.graphQLErrors);
            return;
        }

        if (!settingsLoading && settingsData) {
            const { getSettings } = settingsData;
            if (ArrayUtils.isNotEmpty(getSettings)) {
                const value = Number(getSettings[0].value || 0);

                dispatch({
                    type: ACTION_TYPES.SET_DEFAULT_RECON_COST,
                    value,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsLoading, settingsError]);

    useEffect(() => {
        getCategories({
            variables: {
                activeItems: currentFilter === 0,
            },
        });

        getJobTypes({
            variables: {
                descriptions: [CatalogEnum.JOB_TYPE],
                onlyActive: true,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter]);

    useEffect(() => {
        if (reconInspectorsError) {
            ModalUtils.errorMessage(reconInspectorsError?.graphQLErrors);
            return;
        }

        if (!reconInspectorsLoading) {
            const { getReconInspectors } = reconInspectorsData;
            dispatch({
                type: ACTION_TYPES.SET_INSPECTORS,
                value: getReconInspectors,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reconInspectorsLoading, reconInspectorsError]);

    const onSaveReconCost = () => {
        if (defaultReconCost < 0) return;

        saveInventorySettings({
            variables: {
                input: [{
                    value: defaultReconCost.toString(),
                    key: InventoryCategory.INV_DEFAULT_MAX_RECON_COST,
                    lotName: ALL_LOTS,
                }],
            },
        });
    };

    const changeFilter = (value) => {
        dispatch({
            type: ACTION_TYPES.SET_CURRENT_FILTER,
            value,
        });
    };

    const toggleAddForm = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_ADD_FORM,
        });
    };

    const toggleCatalogManager = (isJobTypes) => {
        refetchCategories();
        refetchJobTypes();

        dispatch({
            type: ACTION_TYPES.TOGGLE_CATALOG_MANAGER_FORM,
            value: isJobTypes,
        });
    };

    const onSaveJobTypes = (data) => {
        try {
            editJobTypes({
                variables: {
                    enumValues: data.map((el) => ({
                        ...el,
                        enumValuesId: el.enumValuesId === -1 ? null : el.enumValuesId,
                    })),
                },
            });
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const onSaveCategories = async (data) => {
        const currentCats = data.filter((cat) => cat.reconInspectionCategoryId >= 0);
        const newCats = data.filter((cat) => cat.reconInspectionCategoryId === -1);
        const promises = [];

        try {
            newCats.forEach((cat) => {
                promises.push(
                    addCategory({
                        variables: {
                            input: {
                                name: cat.name,
                                order: cat.order,
                                jobSpotsSplit: cat.jobSpotsSplit,
                                forTechnicians: cat.forTechnicians,
                            },
                        },
                    }),
                );
            });

            currentCats.forEach((cat) => {
                promises.push(
                    editCategory({
                        variables: {
                            input: {
                                reconInspectionCategoryId: cat.reconInspectionCategoryId,
                                name: cat.name,
                                order: cat.order,
                                active: cat.active,
                                jobSpotsSplit: cat.jobSpotsSplit,
                                forTechnicians: cat.forTechnicians,
                            },
                        },
                    }),
                );
            });

            await Promise.all(promises);
            ModalUtils.successMessage(null, 'Categories updated successfully');

            toggleCatalogManager(false);
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const onChange = ({ target: { name, value } }) => {
        if (name === 'defaultReconCost') {
            dispatch({
                type: ACTION_TYPES.SET_DEFAULT_RECON_COST,
                value,
            });

            return;
        }

        if (name === 'inspector') {
            options.current = value;
            saveDefaultInspector({
                variables: {
                    userId: value,
                },
            });
            return;
        }

        dispatch({
            type: ACTION_TYPES.CHANGE_FIELD_IN_SELECTED,
            value: {
                name,
                value,
            },
        });
    };

    const onCreate = (value) => {
        const payload = {
            name: value,
            order: categories.length + 1,
        };

        options.current = payload;
        addCategory({
            variables: {
                input: payload,
            },
        });
    };

    const restoreItem = (record) => {
        dispatch({
            type: ACTION_TYPES.SET_SELECTED_ITEM,
            value: record,
        });

        modifyItem({
            variables: {
                input: {
                    reconInspectionItemId: record.reconInspectionItemId,
                    reconInspectionCategoryId: record.category.reconInspectionCategoryId,
                    name: record.name,
                    active: true,
                },
            },
        });
    };

    const editItem = (record) => {
        options.current = { ...record };
        dispatch({
            type: ACTION_TYPES.TOGGLE_ADD_FORM,
            value: record,
        });
    };

    const displayRemoveWarning = (record) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_DISPLAY_WARNING,
            value: record,
        });
    };

    const onConfirmDialogClose = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_DISPLAY_WARNING,
            value: {},
        });
    };

    const saveItem = () => {
        try {
            if (!selectedItem.reconInspectionItemId) {
                addItem({
                    variables: {
                        input: {
                            reconInspectionCategoryId: selectedItem.category.reconInspectionCategoryId,
                            name: selectedItem.name,
                            jobTypes: selectedItem.jobTypes || [],
                        },
                    },
                });

                return;
            }

            modifyItem({
                variables: {
                    input: {
                        reconInspectionItemId: selectedItem.reconInspectionItemId,
                        reconInspectionCategoryId: selectedItem.category.reconInspectionCategoryId,
                        name: selectedItem.name,
                        jobTypes: selectedItem.jobTypes || [],
                        active: selectedItem.active,
                    },
                },
            });
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const isItemSaveButtonDisabled = StringUtils.isEmpty(selectedItem?.name)
                                || StringUtils.isEmpty(selectedItem?.category?.reconInspectionCategoryId)
                                || addingItem
                                || modifyingItem;
    const handleKeyDown = (e) => {
        if (e.keyCode === 13 && !isItemSaveButtonDisabled) saveItem();
    };

    const removeItem = () => {
        modifyItem({
            variables: {
                input: {
                    reconInspectionItemId: selectedItem.reconInspectionItemId,
                    reconInspectionCategoryId: selectedItem.category.reconInspectionCategoryId,
                    name: selectedItem.name,
                    active: false,
                },
            },
        });
    };

    const getColumns = () => {
        const columns = [
            {
                headerClassName: classes.tableHeader,
                label: 'Category',
                dataKey: 'category',
                width: 250,
                cellRenderer: (cell) => {
                    const { rowData: record } = cell;

                    return (
                        <span className={classes.text}>{record.category.name}</span>
                    );
                },
            },
            {
                headerClassName: classes.tableHeader,
                label: 'Checklist Item',
                dataKey: 'name',
                width: 250,
                cellRenderer: (cell) => {
                    const { rowData: record } = cell;

                    return (
                        <span className={classes.text}>{record.name}</span>
                    );
                },
            },
            {
                headerClassName: classes.tableHeader,
                label: 'Actions',
                dataKey: 'actions',
                width: 200,
                cellRenderer: (cell) => {
                    const { rowData: record } = cell;
                    if (!canWrite) return null;

                    if (!record.active) {
                        return (
                            <div className={classes.actionsContainer}>
                                <Button
                                    className={classes.containedInfo}
                                    size="small"
                                    startIcon={<RestoreFromTrashIcon />}
                                    onClick={() => restoreItem(record)}
                                />
                            </div>
                        );
                    }

                    return (
                        <div className={classes.actionsContainer}>
                            <Button
                                className={classes.containedInfo}
                                size="small"
                                startIcon={<EditOutlinedIcon />}
                                onClick={() => editItem(record)}
                            />
                            <Button
                                className={classes.containedError}
                                size="small"
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                onClick={() => displayRemoveWarning(record)}
                            />
                        </div>
                    );
                },
            },
        ];

        if (!isMobile) {
            columns.splice(2, 0, {
                headerClassName: classes.tableHeader,
                label: 'Job Types',
                dataKey: 'jobTypes',
                width: 350,
                cellRenderer: (cell) => {
                    const { rowData: record } = cell;
                    const current = record.jobTypes;

                    return (
                        <span className={classes.text}>{jobTypes.filter((e) => current?.includes(e.enumValuesId)).map((e) => e.description).join(', ')}</span>
                    );
                },
            });
        }

        return columns;
    };

    const categoryOptions = categories
        .map((item) => ({
            value: item.reconInspectionCategoryId,
            label: item.name,
        }));
    const defaultInspector = inspectors.find((inspector) => inspector.default);
    const inspectorOptions = inspectors
        .map((item) => ({
            value: item.userID,
            label: `${item.firstName} ${item.lastName}`,
        }));
    const items = getItems(categories);
    return (
        <>
            <Grid container className={classes.box}>
                <Grid item lg={3} xs={12}>
                    <Form.Group className={classes.fieldContainer} as={Col}>
                        <Form.Label className={classes.subLabel}>Default Person for Approvals</Form.Label>
                        <SelectWidget
                            nowrap
                            size="sm"
                            loading={reconInspectorsLoading || savingDefaultInspector}
                            className={classes.input}
                            name="inspector"
                            onChange={(name, value) => onChange({ target: { name, value } })}
                            value={defaultInspector?.userID || ''}
                            options={inspectorOptions}
                        />
                    </Form.Group>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <Form.Group className={classes.fieldContainer} as={Col}>
                        <Form.Label className={classes.subLabel}>Max Recon Cost</Form.Label>
                        <div className="max-cost">
                            <InputNumber
                                value={defaultReconCost}
                                onChange={(newValue) => onChange({ target: { name: 'defaultReconCost', value: newValue } })}
                                placeholder="$0"
                                showCurrency
                                min={0}
                                decimalScale={0}
                                thousandSeparator
                                size="sm"
                            />
                            <Button
                                size="small"
                                className={classes.containedSecondaryInfo}
                                onClick={onSaveReconCost}
                            >
                                Save
                            </Button>
                        </div>
                    </Form.Group>
                </Grid>
                <Grid item xs={12}>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.labels}>Inspection Items</Form.Label>
                        <Divider />
                    </Form.Group>
                </Grid>
                <Grid item lg={11} xs={12}>
                    <FormControl component="fieldset" className={clsx(classes.boxContainer, classes.paddingLeft25)}>
                        <RadioGroup row value={currentFilter} onChange={(e, val) => changeFilter(Number(val))}>
                            <FormControlLabel
                                value={0}
                                control={<Radio color="primary" />}
                                label="Active"
                            />
                            <FormControlLabel
                                value={1}
                                control={<Radio color="primary" />}
                                label="Inactive"
                            />
                        </RadioGroup>
                        <div className={classes.otherActions}>
                            <If condition={canWrite}>
                                <Button
                                    size="small"
                                    startIcon={<AddOutlinedIcon />}
                                    className={classes.containedSecondaryInfo}
                                    onClick={toggleAddForm}
                                >
                                    Add Item
                                </Button>
                                <Button
                                    size="small"
                                    startIcon={<ImportExportOutlinedIcon />}
                                    className={classes.containedSecondaryInfo}
                                    onClick={() => toggleCatalogManager(false)}
                                >
                                    {isMobile ? 'Categories' : 'Manage Categories'}
                                </Button>
                                <Button
                                    size="small"
                                    className={classes.containedSecondaryInfo}
                                    onClick={() => toggleCatalogManager(true)}
                                >
                                    {isMobile ? 'Job Types' : 'Manage Job Types'}
                                </Button>
                            </If>
                        </div>
                    </FormControl>
                </Grid>
                <Grid item lg={11} xs={12}>
                    <div className={classes.tableContainer}>
                        <VirtualTable
                            loading={loadingCategories}
                            rowHeight={45}
                            totalRecords={items.length}
                            data={items}
                            columns={getColumns()}
                        />
                    </div>
                </Grid>
            </Grid>
            <Dialog
                open={isAddFormOpen}
                maxWidth="sm"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title="Add/Edit Item"
                    onClose={toggleAddForm}
                    toolbarSize="md"
                />
                <DialogContent>
                    <div className={classes.content}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.dialogLabels}>Category (You can type in brand new categories)</Form.Label>
                            <SelectWidget
                                nowrap
                                creatable
                                loading={addingCategory}
                                className={classes.input}
                                name="category"
                                onCreate={(value) => onCreate(value)}
                                onChange={(name, value) => onChange(
                                    { target: { name, value } },
                                )}
                                value={selectedItem?.category?.reconInspectionCategoryId || ''}
                                options={categoryOptions}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.dialogLabels}>Name (255 Chars Max)</Form.Label>
                            <Form.Control
                                maxLength="255"
                                className={classes.input}
                                type="text"
                                name="name"
                                value={selectedItem?.name || ''}
                                onChange={onChange}
                                onKeyDown={handleKeyDown}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.dialogLabels}>Job Types</Form.Label>
                            <Select
                                className={classes.select}
                                multiple
                                name="jobTypes"
                                value={selectedItem?.jobTypes || []}
                                onChange={onChange}
                                renderValue={(selected) => selected.map((id) => jobTypes.find((el) => el.enumValuesId === id)?.description).join(', ')}
                                MenuProps={MenuProps}
                            >
                                {jobTypes.map(({ enumValuesId, description }, index) => (
                                    <MenuItem key={index} value={enumValuesId}>
                                        <Checkbox checked={(selectedItem.jobTypes || []).includes(enumValuesId)} />
                                        <ListItemText primary={description} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Form.Group>
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Form.Group as={Col}>
                        <Button
                            size="small"
                            className={classes.containedSecondaryInfo}
                            disabled={isItemSaveButtonDisabled}
                            onClick={saveItem}
                        >
                            Save
                        </Button>
                    </Form.Group>
                </DialogActions>
            </Dialog>
            <If condition={isCatalogManagerFormOpen && !loadingCategories && !loadingJobTypes}>
                <CatalogManager
                    isJobTypesCatalog={isJobTypesCatalog}
                    categories={categories}
                    jobTypes={jobTypes}
                    onClose={() => toggleCatalogManager(false)}
                    onSave={isJobTypesCatalog ? onSaveJobTypes : onSaveCategories}
                />
            </If>
            <ConfirmDialog
                title="Attention!"
                description={`
                    This item will be deactivated, however we will keep current associations.
                    Later you can reactivate it on the inactivate items.
                    Continue?
                `}
                open={isRemoveWarningOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onConfirmDialogClose}
                onClickSecondary={onConfirmDialogClose}
                onClickPrimary={removeItem}
            />
        </>
    );
};

ReconSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default ReconSettings;
