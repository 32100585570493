import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles, Button,
    Dialog, DialogContent,
    DialogActions, TextField,
} from '@material-ui/core';
import { Col, Form } from 'react-bootstrap';
import { FetchPolicy } from 'utils/enum/Core';
import { PartStatus } from 'utils/enum/InventoryEnum';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import useImagesActions from 'components/modules/inventory/hooks/useImagesActions';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';
import InspectionImagesDialog from 'components/modules/inventory/read/InspectionImagesDialog';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '15px',
            color: theme.palette.text.white,
        },
    },
    noSpots: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    content: {
        padding: '20px 10px',
        overflowX: 'auto',
        overflowY: 'auto',
        maxHeight: '500px',
        minHeight: '350px',
        position: 'relative',
        '& > span:nth-child(1)': {
            fontSize: '14px',
        },
        '& > div:nth-child(2)': {
            fontSize: '14px',
            fontWeight: 500,
        },
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
        },
    },
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
    uploadButton: {
        fontSize: '12px',
        borderRadius: '0px',
        backgroundColor: theme.palette.primary.main,
    },
    greenUploadButton: {
        backgroundColor: `${theme.palette.background.green} !important`,
    },
    header: {
        marginBottom: '15px',
        fontSize: '14px',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        '& > div': {
            display: 'inline-block',
            color: theme.palette.text.white,
            backgroundColor: theme.palette.background.eucalyptus,
            padding: '5px',
        },
        '& > div:nth-child(1)': {
            width: '100px',
        },
        '& > div:nth-child(2)': {
            width: '250px',
        },
        '& > div:nth-child(3)': {
            width: '100px',
        },
        '& > div:nth-child(4)': {
            width: '200px',
        },
        '& > div:nth-child(5)': {
            width: '120px',
        },
        '& > div:nth-child(6)': {
            width: '180px',
        },
        '& > div:nth-child(7)': {
            width: '220px',
        },
        '& > div:nth-child(8)': {
            width: '220px',
        },
    },
    partRow: {
        marginBottom: '10px',
        display: 'inline-flex',
        alignItems: 'center',
        '& > div': {
            padding: '5px',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        '& > div:nth-child(1)': {
            width: '100px',
        },
        '& > div:nth-child(2)': {
            width: '250px',
            '& > div': {
                width: '100%',
            },
        },
        '& > div:nth-child(3)': {
            width: '100px',
        },
        '& > div:nth-child(4)': {
            width: '200px',
        },
        '& > div:nth-child(5)': {
            width: '120px',
        },
        '& > div:nth-child(6)': {
            width: '180px',
        },
        '& > div:nth-child(7)': {
            width: '220px',
        },
        '& > div:nth-child(8)': {
            width: '220px',
        },
    },
}));

const PartsRequest = ({
    techniciansCategories,
    closeForm,
    job,
}) => {
    const {
        moveType,
        addImages,
        deleteImages,
        setPhotoIndex,
    } = useImagesActions();
    const classes = { ...useStyles(), ...buttonStyles() };
    const {
        invoiceNumber,
        jobNumber,
        customerStates,
        serviceInvoice,
    } = job;

    const {
        vehicleStock,
        vehicleMake,
        vehicleModel,
        vehicleTrim,
        vehicleYear,
    } = serviceInvoice;

    const [state, setState] = useState({
        categories: techniciansCategories
            .map((c) => {
                const clone = { ...c };
                const { jobSpotsSplit } = clone;

                clone.items = clone.items
                    .filter((_, index) => index >= ((jobNumber - 1) * jobSpotsSplit) && index <= ((jobNumber * jobSpotsSplit) - 1));

                return clone;
            }),
        requests: [],
        selectedItem: null,
        selectedItemImages: null,
        isPicturesFormOpen: false,
        openImagePreview: false,
        photoIndex: -1,
        isUploadingImages: false,
    });

    const {
        categories,
        selectedItem,
        selectedItemImages,
        isPicturesFormOpen,
        openImagePreview,
        photoIndex,
        isUploadingImages,
    } = state;

    const {
        data: savedItemsData,
        loading: loadingSavedItems,
        error: errorSavedItems,
        refetch: refetchSavedItems,
    } = useQuery(ServiceQuery.GET_SAVED_INSPECTION_ITEMS, {
        variables: {
            invoiceNumber,
        },
        skip: categories.length === 0,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        notifyOnNetworkStatusChange: true,
    });

    const [getImages] = useLazyQuery(InventoryQuery.GET_VEHICLE_RECON_ITEM_IMAGES, {
        onCompleted: (response) => {
            if (response) {
                const images = response.getVehicleReconItemImages;

                setState((prev) => ({
                    ...prev,
                    selectedItemImages: images,
                }));
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (errorSavedItems) {
            ModalUtils.errorMessage(errorSavedItems?.graphQLErrors);
        }

        if (!loadingSavedItems) {
            const savedItems = savedItemsData?.getSavedInspectionItems;
            if (Array.isArray(savedItems)) {
                const clone = categories.map((c) => {
                    const catClone = { ...c };

                    catClone.items = catClone.items.map((i) => {
                        const itemClone = { ...i };
                        const savedItem = savedItems.find((x) => x.reconInspectionItemId === i.reconInspectionItemId);

                        if (savedItem) {
                            const { repairs = [] } = savedItem;

                            itemClone.current = {
                                reconInspectionItemId: savedItem.reconInspectionItemId,
                                reconInspectionItemName: i.name,
                                vehicleInspectionItemId: savedItem.vehicleInspectionItemId,
                                comment: savedItem.comment,
                                jobNumber: savedItem.jobNumber,
                                wasModified: false,
                                modifiedByUser: savedItem.modifiedByUser,
                                estimation: savedItem.estimation,
                                images: savedItem.images,
                                partStatus: repairs.length > 0 ? repairs[0].partStatus : '',
                                partStatusModifiedOn: repairs.length > 0 ? repairs[0].partStatusModifiedOn : '',
                                partStatusETA: repairs.length > 0 ? repairs[0].partStatusETA : '',
                                pass: false,
                                fail: true,
                                part: true,
                                repair: true,
                            };
                        } else {
                            itemClone.current = null;
                        }

                        return itemClone;
                    });

                    return catClone;
                });

                setState((prev) => ({
                    ...prev,
                    categories: clone,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSavedItems, errorSavedItems]);

    const [saveRequets, { loading: savingRequets }] = useMutation(InventoryMutation.SAVE_VEHICLE_RECON_ITEMS, {
        onCompleted: (response) => {
            if (response?.saveVehicleReconItems) {
                ModalUtils.successMessage(null, 'Requests saved successfully');
                refetchSavedItems();
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const onChange = (
        inspectionItemId,
        reconInspectionItemName,
        vehicleInspectionItemId,
        value,
    ) => {
        const clone = categories.map((c) => {
            const catClone = { ...c };
            catClone.items = catClone.items.map((i) => {
                const itemClone = { ...i };
                if (itemClone.reconInspectionItemId === inspectionItemId) {
                    const currentComment = itemClone.current?.comment;
                    itemClone.current = {
                        ...(itemClone.current ?? {}),
                        reconInspectionItemId: inspectionItemId,
                        reconInspectionItemName,
                        vehicleInspectionItemId,
                        pass: false,
                        fail: true,
                        part: true,
                        repair: true,
                        comment: value,
                        jobNumber,
                        wasModified: currentComment?.toLowerCase() !== value?.toLowerCase(),
                    };
                }

                return itemClone;
            });

            return catClone;
        });

        setState((prev) => ({
            ...prev,
            categories: clone,
        }));
    };

    const onSaveRequests = () => {
        const items = categories
            .reduce((a, b) => [...a, ...b.items], [])
            .map((i) => i.current)
            .filter((i) => i != null)
            .map(({
                reconInspectionItemId,
                reconInspectionItemName,
                vehicleInspectionItemId,
                pass,
                fail,
                part,
                repair,
                comment,
                estimation,
                jobNumber: setJobNumber,
                wasModified,
            }) => ({
                reconInspectionItemId,
                reconInspectionItemName,
                vehicleInspectionItemId,
                pass,
                fail,
                part,
                repair,
                comment,
                estimation,
                jobNumber: setJobNumber,
                wasModified,
            }));

        saveRequets({
            variables: {
                invoiceNumber,
                items,
            },
        });
    };

    const togglePicturesForm = (item) => {
        if (!item) {
            setState((prev) => ({
                ...prev,
                isPicturesFormOpen: !isPicturesFormOpen,
                selectedItem: null,
                selectedItemImages: null,
            }));

            refetchSavedItems();
            return;
        }

        setState((prev) => ({
            ...prev,
            isPicturesFormOpen: !isPicturesFormOpen,
            selectedItem: item,
            selectedItemImages: (!item ? null : selectedItemImages),
        }));

        getImages({
            variables: {
                vehicleInspectionItemId: item.vehicleInspectionItemId,
            },
        });
    };

    const onOpenImage = (index) => {
        setState((prev) => ({
            ...prev,
            openImagePreview: !openImagePreview,
            photoIndex: index,
        }));
    };

    const toggleImagePreview = () => {
        setState((prev) => ({
            ...prev,
            openImagePreview: !openImagePreview,
            photoIndex: -1,
        }));
    };

    const onSetPhotoIndex = (move) => setPhotoIndex(
        move,
        photoIndex,
        selectedItemImages,
        setState,
    );

    const onAddImages = async (files) => {
        const dispatcherPayload = {
            ...state,
            isUploadingImages: true,
        };

        const additionalDispatcherPayload = {
            ...state,
            isUploadingImages: false,
        };

        addImages(
            files,
            setState,
            dispatcherPayload,
            additionalDispatcherPayload,
            'vehicleInspectionItemId',
            selectedItem.vehicleInspectionItemId,
            invoiceNumber,
            InventoryMutation.UPLOAD_VEHICLE_RECON_ITEM_IMAGE,
            'uploadVehicleReconItemImage',
            InventoryMutation.SAVE_VEHICLE_RECON_ITEM_IMAGES,
            getImages,
        );
    };

    const onDeleteImages = async (selectedImages, toggleImagesDeletionPrompt) => {
        const payload = {
            variables: {
                vehicleInspectionItemId: selectedItem.vehicleInspectionItemId,
            },
        };

        deleteImages(
            selectedItemImages,
            'vehicleInspectionItemImageId',
            InventoryMutation.REMOVE_VEHICLE_RECON_ITEM_IMAGES,
            selectedImages,
            toggleImagesDeletionPrompt,
            getImages,
            payload,
        );
    };

    const vehicleTitle = `${vehicleStock > 0 ? `${vehicleStock} - ` : ''}${vehicleYear} ${vehicleMake} ${vehicleModel} ${vehicleTrim}`;
    const noSpotsAvailable = categories.every((c) => (c.items?.length ?? 0) === 0);
    return (
        <>
            <Dialog
                open
                maxWidth="md"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title={`Request Parts | RO: ${invoiceNumber} - Job #: ${jobNumber} | Vehicle: ${vehicleTitle}`}
                    onClose={closeForm}
                    toolbarSize="md"
                />
                <DialogContent>
                    {noSpotsAvailable && (
                        <span className={classes.noSpots}>No spots available</span>
                    )}
                    <div onScroll={(e) => e.stopPropagation()} className={classes.content}>
                        <span>{`Customer States: ${customerStates}`}</span>
                        <div className={classes.header}>
                            <div>Category</div>
                            <div>Part</div>
                            <div>Pictures</div>
                            <div>Modified By</div>
                            <div>Estimate</div>
                            <div>Part Status</div>
                            <div>Timestamp</div>
                            <div>ETA</div>
                        </div>
                        {categories.map(({
                            name: categoryName,
                            items,
                        }) => items.map(({
                            reconInspectionItemId,
                            name: reconInspectionItemName,
                            current,
                        }, index) => (
                            <div key={index} className={classes.partRow}>
                                <div>{categoryName}</div>
                                <div>
                                    <TextField
                                        disabled={current?.partStatus === PartStatus.PART_RECEIVED}
                                        className={classes.textFieldSmall}
                                        value={current?.comment ?? ''}
                                        variant="outlined"
                                        size="small"
                                        onChange={({ target: { value } }) => onChange(
                                            reconInspectionItemId,
                                            reconInspectionItemName,
                                            current?.vehicleInspectionItemId,
                                            value,
                                        )}
                                    />
                                </div>
                                <div>
                                    {current?.vehicleInspectionItemId != null && (
                                        <Button
                                            size="small"
                                            className={clsx(
                                                classes.containedSecondaryInfo,
                                                classes.uploadButton,
                                                (current?.images?.length || 0) > 0 ? classes.greenUploadButton : '',
                                            )}
                                            onClick={() => togglePicturesForm(current)}
                                        >
                                            {`View (${current?.images?.length ?? 0})`}
                                        </Button>
                                    )}
                                </div>
                                <div>{current?.modifiedByUser}</div>
                                <div>{current?.estimation ? NumberUtils.applyCurrencyFormat(current.estimation) : ''}</div>
                                <div>{current?.partStatus}</div>
                                <div>
                                    {current?.partStatusModifiedOn
                                        ? DateUtils.getFormattedDateInUserTimezone(current.partStatusModifiedOn, DateFormat.DEFAULT_DATETIME_WITHOUT_SECONDS)
                                        : ''}
                                </div>
                                <div>
                                    {current?.partStatusETA
                                        ? DateUtils.getFormattedDateInUserTimezone(current.partStatusETA, DateFormat.DEFAULT_DATETIME_WITHOUT_SECONDS)
                                        : ''}
                                </div>
                            </div>
                        )))}
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Form.Group as={Col}>
                        <Button
                            size="small"
                            className={classes.containedSecondaryInfo}
                            disabled={savingRequets || noSpotsAvailable}
                            onClick={onSaveRequests}
                        >
                            Save
                        </Button>
                    </Form.Group>
                </DialogActions>
            </Dialog>
            <InspectionImagesDialog
                isPicturesFormOpen={isPicturesFormOpen}
                isUploadingImages={isUploadingImages}
                openImagePreview={openImagePreview}
                addImages={onAddImages}
                togglePicturesForm={togglePicturesForm}
                selectedItemImages={selectedItemImages}
                onOpenImage={onOpenImage}
                photoIndex={photoIndex}
                toggleImagePreview={toggleImagePreview}
                setPhotoIndex={onSetPhotoIndex}
                onDeleteImages={onDeleteImages}
                moveType={moveType}
                isApproved={false}
                canWrite
            />
        </>
    );
};

PartsRequest.propTypes = {
    techniciansCategories: PropTypes.array.isRequired,
    closeForm: PropTypes.func.isRequired,
    job: PropTypes.object.isRequired,
};

export default PartsRequest;
