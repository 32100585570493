import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Material UI
import {
    makeStyles,
    FormLabel,
    Tooltip,
} from '@material-ui/core';
import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';

// Components
import If from 'components/widgets/conditional/If';
import StringUtils from 'lib/StringUtils';
import UserContext from 'components/context/UserContext';
import useCustomStockNumber from 'components/hook/inventory/useCustomStockNumber';

const useStyles = makeStyles(InventoryListStyle.vehicleWidget());

const VehicleWidget = ({ record, short }) => {
    const model = [];
    const classes = useStyles();
    const { userInformation } = useContext(UserContext);
    const customizedStockNumber = useCustomStockNumber(userInformation, record);

    if (record.model) model.push(record.model);

    if (record.trim) model.push(record.trim);

    return (
        <div className={classes.main}>
            <div className={classes.data}>
                <div className={classes.yearWholesaleContainer}>
                    <If condition={short}>
                        <div className={classes.bold}>{customizedStockNumber === 0 ? '' : customizedStockNumber}</div>
                    </If>
                    <If condition={!short}>
                        <div className={classes.bold}>{record.year}</div>
                    </If>
                    {record.wholesale && (
                        <FormLabel className={classes.wholesaleLabel}>
                            Wholesale
                        </FormLabel>
                    )}
                </div>
                <div className="make">{short ? `${record.make} - ${record.year}` : record.make}</div>
                <div className={classes.model}>{model.join(' - ')}</div>
                <div className="vin">{record.vin?.toUpperCase()}</div>
                <If condition={!short && !record.placeAd}>
                    <div className={classes.notAdvertised}>Not Advertised</div>
                </If>
            </div>
            <If condition={!short}>
                <div className={classes.secondColumn}>
                    <div>
                        <span>Stock: </span>
                        <span className={`${classes.bold} ${classes.stockCell}`}>
                            {customizedStockNumber}
                        </span>
                    </div>
                    <If condition={!StringUtils.isEmpty(record.drivetrain)}>
                        <div>
                            <span>
                                {record.drivetrain}
                            </span>
                        </div>
                    </If>
                    <Tooltip placement="bottom" title="Vehicle Photos">
                        <span className={classes.photoInfo}>
                            <i className="icon" />
                            {record.imageCounter || 0}
                        </span>
                    </Tooltip>
                </div>
            </If>
        </div>
    );
};

VehicleWidget.propTypes = {
    record: PropTypes.object.isRequired,
    short: PropTypes.bool,
};

VehicleWidget.defaultProps = {
    short: false,
};

export default VehicleWidget;
