import { gql } from '@apollo/client';

export default class AccountReceivableMutation {
static CREATE_CASHIER_PAYMENT = gql`
  mutation createCashierPayment($record: CashierInput!) {
    createCashierPayment(record: $record)
  }
`;

static UPDATE_CASHIER_PAYMENT = gql`
  mutation updateCashierPayment($record: CashierInput!) {
    updateCashierPayment(record: $record)
  }
`;

static SAVE_CASHIER_MAPPING_CREDIT = gql`
        mutation saveCashierMappingCredit($data: [CashierMappingCreditDataInput!]!, $key: String) {
          saveCashierMappingCredit(data: $data, key: $key)
        }
    `;

static SAVE_CASHIER_MAPPING_DEBIT = gql`
    mutation saveCashierMappingDebit($data: CashierMappingDebitDataInput!) {
      saveCashierMappingDebit(data: $data)
    }
`;

static VOID_CASHIER_PAYMENT = gql`
  mutation voidChashierPayment($transactionNumber: Int!, $comment: String) {
    voidChashierPayment(transactionNumber: $transactionNumber, comment: $comment)
  }
`;

static SAVE_CASHIER_MAPPING_CUSTOM_FIELD = gql`
    mutation saveCashierMappingCustomField($input: CashierMappingCustomFieldDataInput!) {
      saveCashierMappingCustomField(input: $input)
    }
`;

static CREATE_ELECTRONIC_PAYMENT = gql`
  mutation createElectronicPayment($record: ElectronicPaymentInput!) {
    createElectronicPayment(record: $record)
  }
`;

static CREATE_ELECTRONIC_ORDER = gql`
  mutation createOrder($accountNumber: Int!, $processType: String!, $reason: String!, $amount: Float!) {
    createOrder(accountNumber: $accountNumber, processType: $processType, reason: $reason, amount: $amount){
      pnmOrderIdentifier
      siteOrderKey
      orderCreated
      orderStatus
      orderAmount
      electronicPayments {
          embeddedJSUrl
      }
    }
  }
`;

static REFUND_ELECTRONIC_PAYMENT = gql`
  mutation refundPayment($cashierElectronicPaymentId: String!, $comment: String) {
    refundPayment(cashierElectronicPaymentId: $cashierElectronicPaymentId, comment: $comment)
  }
`;
}
