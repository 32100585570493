import { useReducer, useEffect, useContext } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { DataSort, FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import { EmployeeType } from 'utils/enum/UserEnum';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';
import UserQuery from 'services/graphQL/query/UserQuery';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import ModalUtils from 'utils/ModalUtils';
import UserContext from 'components/context/UserContext';

const ACTION_TYPES = {
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_TECHNICIANS: 'setTechnicians',
    SET_TECHNICIAN: 'setTechnician',
    SET_LOADING: 'setLoading',
    SET_LOT_DATA: 'setLotData',
    SET_RECON_CATEGORIES: 'setReconCategories',
    ON_REFRESH: 'onRefresh',
    ON_CHANGE_STATUS: 'onChangeStatus',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.SET_TECHNICIANS: {
        return {
            ...state,
            technicians: action.value,
        };
    }
    case ACTION_TYPES.SET_TECHNICIAN: {
        return {
            ...state,
            selectedTechnician: action.value,
            table: {
                records: [],
                totalCount: 0,
            },
            params: {
                ...state.params,
                paginate: {
                    init: 0,
                    limit: 10,
                },
            },
        };
    }
    case ACTION_TYPES.SET_LOT_DATA: {
        const { lots, selectedLots } = action.value;
        return {
            ...state,
            lots: lots ?? state.lots,
            selectedLots,
            table: {
                records: [],
                totalCount: 0,
            },
            params: {
                ...state.params,
                paginate: {
                    init: 0,
                    limit: 10,
                },
            },
        };
    }
    case ACTION_TYPES.SET_RECON_CATEGORIES: {
        return {
            ...state,
            reconCategories: action.value,
        };
    }
    case ACTION_TYPES.ON_REFRESH: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: {
                ...state.params,
                paginate: {
                    init: 0,
                    limit: 10,
                },
            },
        };
    }
    case ACTION_TYPES.ON_CHANGE_STATUS: {
        const { name, value } = action.value;
        return {
            ...state,
            [name]: value,
            table: {
                records: [],
                totalCount: 0,
            },
            params: {
                ...state.params,
                paginate: {
                    init: 0,
                    limit: 10,
                },
            },
        };
    }
    default: return action.value;
    }
};

const useTechnicianJobs = () => {
    const { userInformation = {} } = useContext(UserContext);
    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 10,
            },
            search: null,
        },
        table: {
            records: [],
            totalCount: 0,
        },
        lots: [],
        selectedLots: null,
        technicians: [],
        reconCategories: [],
        selectedTechnician: null,
        roStatus: 'All RO Statuses',
        jobStatus: 'All Job Statuses',
    };
    const [state, dispatch] = useReducer(reducer, initState);
    const {
        params,
        params: {
            paginate,
            search,
        },
        table: {
            records,
        },
        lots,
        selectedLots,
        selectedTechnician,
        reconCategories,
        roStatus,
        jobStatus,
    } = state;

    const [usersData, { loading: loadingUsers }] = useLazyQuery(UserQuery.GET_USER_AND_SALARY_INFO, {
        variables: {
            types: [EmployeeType.TECHNICIAN],
        },
        onCompleted: (res) => {
            const { getUsersAndSalary: data } = res;

            if (data?.length > 0) {
                dispatch({
                    type: ACTION_TYPES.SET_TECHNICIANS,
                    value: [...data].sort((a, b) => a.firstName.localeCompare(b.firstName)),
                });
            }
        },
        onError: (errorMessage) => {
            const { message, graphQLErrors } = errorMessage;
            ModalUtils.errorMessage((graphQLErrors?.length > 0 ? graphQLErrors : null), message);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const isAllLots = (selectedLots ?? []).includes(ALL_LOTS);
    const {
        data,
        loading,
        error,
        refetch: refetchJobs,
    } = useQuery(ServiceQuery.GET_TECHNICIAN_JOBS, {
        variables: {
            paginate,
            filter: {
                searchTerm: search,
            },
            sort: [
                { fieldName: 'invoiceNumber', dir: DataSort.DESC },
                { fieldName: 'jobNumber', dir: DataSort.ASC },
            ],
            technicianId: selectedTechnician,
            lots: isAllLots ? (lots ?? []).map((i) => i.lotName) : selectedLots,
            roStatus: roStatus !== 'All RO Statuses' ? roStatus : null,
            jobStatus: jobStatus !== 'All Job Statuses' ? jobStatus : null,
        },
        skip: selectedTechnician == null || selectedLots == null,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: reconCategoriesData,
        loading: loadingCategories,
        error: errorLoadingCategories,
    } = useQuery(InventoryQuery.GET_INSPECTION_CATEGORIES, {
        variables: {
            activeItems: true,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (errorLoadingCategories) {
            ModalUtils.errorMessage(errorLoadingCategories?.graphQLErrors);
        }

        if (!loadingCategories) {
            const categories = reconCategoriesData.getInspectionCategories;
            dispatch({
                type: ACTION_TYPES.SET_RECON_CATEGORIES,
                value: categories,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCategories, errorLoadingCategories]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }

        if (!loading) {
            const response = data?.getTechniciansJobs;
            if (response) {
                const { data: dataRecords, totalCount } = response;

                if (dataRecords?.length > 0) {
                    records.push(...dataRecords);

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records,
                            totalCount,
                        },
                    });
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    useEffect(() => {
        if (Object.keys(userInformation ?? {}).length > 0) {
            const defaultLot = userInformation?.defaultLot ?? userInformation?.usersLot;
            dispatch({
                type: ACTION_TYPES.SET_LOT_DATA,
                value: {
                    lots: userInformation?.lots ?? [],
                    selectedLots: [defaultLot],
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation]);

    const onRefreshJobs = () => {
        dispatch({ type: ACTION_TYPES.ON_REFRESH });
        refetchJobs();
    };

    const onFilterChange = (param, value) => {
        if (params[param] === value) return;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                [param]: value,
                paginate: {
                    ...paginate,
                    init: 0,
                },
            },
        });
    };

    const onFilterByLot = (input) => {
        dispatch({
            type: ACTION_TYPES.SET_LOT_DATA,
            value: {
                selectedLots: input,
            },
        });
    };

    const onSearch = (text) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...paginate,
                    init: 0,
                },
            },
        });
    };

    const onChangeStatus = (name, value) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_STATUS,
            value: {
                name,
                value,
            },
        });
    };

    const onChangeTechnician = (id) => {
        dispatch({
            type: ACTION_TYPES.SET_TECHNICIAN,
            value: id,
        });
    };

    const loadMore = () => {
        const currentOffset = records?.length || 0;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    return {
        state,
        loading,
        loadMore,
        usersData,
        loadingUsers,
        onSearch,
        onFilterChange,
        onChangeTechnician,
        onFilterByLot,
        selectedLots,
        reconCategories,
        onRefreshJobs,
        onChangeStatus,
    };
};

export default useTechnicianJobs;
